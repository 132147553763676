import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'
import type { AuthUser, LoginParams, LoginResponse } from '/@src/types/auth'
import { AuthStatus } from '/@src/types/auth'
import type { ApiResponse, MaybeArray } from '/@src/types/utils'
import * as Sentry from '@sentry/browser'
import { cloneDeep } from 'lodash'
import { type UserId, UserRoleEnum } from '/@src/types/users'
import { toast } from 'vue-sonner'
import { allowReadOnly, blockReadOnly } from '/@src/utils/axios-utils'
import { undefinedUserId } from '/@src/models/users'
import { hasRole } from '/@src/utils/helpers'
import { sendSentryMessage } from '/@src/utils/SentryMessage'
import { usePersist } from '/@src/utils/pinia/pinia-persist-helper'

interface State {
  user?: AuthUser
  userHash?: string
  status: AuthStatus
  adminUser?: AuthUser
  errors?: string
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: undefined,
    userHash: undefined,
    status: AuthStatus.UNAUTHORIZED,
    adminUser: undefined,
    errors: undefined,
  }),
  persist: usePersist<State>(true),
  logout: {
    pick: ['errors'],
  },
  actions: {
    _loggedIn(authUser: AuthUser) {
      this.user = authUser
      this.status = AuthStatus.AUTHORIZED
      this.errors = undefined

      Sentry.setUser({ username: `${authUser.firstName} ${authUser.lastName}` })
      window.connectEcho()
    },

    _error(error: string) {
      this.user = undefined
      this.status = AuthStatus.UNAUTHORIZED_WITH_ERROR
      this.errors = error
    },

    _loggedOut() {
      this.user = undefined
      this.status = AuthStatus.UNAUTHORIZED
      this.errors = undefined

      Sentry.setUser(null)

      const pinia = getActivePinia()
      // Pinia hides this property, so we sadly have to cast to any
      const stores = (pinia as any | undefined)?._s
      stores?.forEach((store: { onLogout: () => void }) => {
        store.onLogout()
      })

      if (!stores) {
        sendSentryMessage('Pinia stores not found!', 'pinia')
      }
    },

    async login(loginParams: LoginParams) {
      return new Promise<boolean>((resolve) => {
        window
          .api!.post<ApiResponse<LoginResponse>>(
            'admin/auth/login',
            loginParams,
            allowReadOnly(),
          )
          .then((response) => {
            const jsonResponse = response && response.data
            this._loggedIn(jsonResponse.data.authUser)
            resolve(true)
          })
          .catch((error) => {
            if (error.response?.status === 401) {
              toast.error('E-mailadres en/of wachtwoord klopt niet')
            }

            this._error(error)
            resolve(false)
          })
      })
    },

    async check() {
      return new Promise<boolean>((resolve) => {
        window
          .api!.post<ApiResponse<LoginResponse>>('admin/auth/me', allowReadOnly())
          .then(
            (response) => {
              const jsonResponse = response && response.data
              this._loggedIn(jsonResponse.data.authUser)
              resolve(true)
            },
            (error) => {
              this._error(error)
              resolve(false)
            },
          )
      })
    },

    async logOut() {
      try {
        await window.disconnectEcho()

        await window.api!.post<AuthUser>('admin/auth/logout', allowReadOnly()).then(
          () => {
            if (this.adminUser) {
              this.user = cloneDeep(this.adminUser)
              this.adminUser = undefined
            } else {
              this.user = undefined
              this.errors = undefined
              this.status = AuthStatus.UNAUTHORIZED
            }
          },
          () => {},
        )
      } catch (error) {}
      this._loggedOut()
    },

    async fetchUserHash() {
      return new Promise<string>((resolve) => {
        window.api!.get<{ hash: string }>('admin/users/hash').then((response) => {
          resolve(response.data.hash)
        })
      })
    },

    async loginAs(userId: UserId) {
      return new Promise<boolean>((resolve) => {
        window
          .api!.get<ApiResponse<LoginResponse>>(
            `admin/auth/login-as/${userId}`,
            blockReadOnly(),
          )
          .then((response) => {
            const jsonResponse = response && response.data

            this.adminUser = cloneDeep(this.user)
            this.user = jsonResponse.data.authUser
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
  },

  getters: {
    isAuthenticated: (state: State): boolean => {
      return (
        !!state.user &&
        !!state.user.token.accessToken &&
        state.status === AuthStatus.AUTHORIZED
      )
    },

    authUser: (state: State): AuthUser => {
      return (
        state.user ?? {
          name: 'Onbekend',
          firstName: 'Onbekend',
          lastName: 'Onbekend',
          email: '',
          roles: [],
          avatar: '',
          authenticated: false,
          id: undefinedUserId,
          token: {
            accessToken: '',
            tokenType: '',
            expiresIn: -1,
          },
        }
      )
    },

    authUserInitials: (state: State): string => {
      if (!state.user) {
        return ''
      }

      const firstNameLetter = state.user.firstName.replace(/[a-z\u00E0-\u00FC]/g, '')
      const lastNameLetter = state.user.lastName.replace(/[a-z\u00E0-\u00FC ]/g, '')

      // Remove lowercase letters and return initials
      return `${firstNameLetter}${lastNameLetter}`
    },

    authorizationHeader: (state: State): string => {
      return `Bearer ${state.user?.token.accessToken}`
    },

    hasReadonlyRole: (state: State): boolean => {
      return (
        !!state.user &&
        state.user.roles.some((role) => role.role === UserRoleEnum.ReadOnly)
      )
    },

    hasRole:
      (state: State) =>
      (roleType: MaybeArray<UserRoleEnum>): boolean => {
        if (!state.user) {
          return false
        }

        if (!Array.isArray(roleType)) {
          roleType = [roleType]
        }

        return roleType.some((r) => hasRole(state.user!, r))
      },
  },
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
