<script lang="ts" setup>
import { toast } from 'vue-sonner'
import { clone, startCase } from 'lodash'
import type { GroupOptions, OptionsMap } from '/@src/types/elements-ui'
import type {
  SignupReference,
  SignupReferenceId,
  UpdateSignupReferenceParams,
} from '/@src/types/signup-references'
import { useSignupReferencesStore } from '/@src/stores/signupReferences'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'
import { useTypedForm } from '/@src/composable/useTypedForm'
import { z } from 'zod'
import { kebabCase, snakeCaseToCamelCase } from '/@src/utils/helpers'
import { nullableDayJSZod, removeKeysForResetForm } from '/@src/utils/zodUtils'
import {
  SignupReferenceMiscId,
  SignupReferenceOopoehId,
  SignupReferenceUnknownId,
} from '/@src/mapping/signup-references'
import type { CompleteAddress } from '/@src/types/utils'
import { SaveIcon } from '/@src/models/standardIcons'
import { dayjsUTC } from '/@src/utils/date-formatter'
import { RoleTypeEnum, RoleTypeOptions } from '/@src/types/shared'
import { roleTypeOptions } from '/@src/mapping/shared'

definePage({
  meta: {
    roles: ['manager', 'impact-accelerator', 'marketing'],
  },
})

const signupReferenceStore = useSignupReferencesStore()
const focusMunicipalitiesStore = useFocusMunicipalitiesStore()

const isLoading = ref(false)
const activeSignupReferenceId = ref<SignupReferenceId>()
const municipalityName = ref('general')

const newSignupReferenceMode = ref(true)

const isActiveFieldDisabled = ref(false)

const camelCaseMunicipality = computed(() => snakeCaseToCamelCase(municipalityName.value))
const startCaseMunicipality = computed(() => startCase(municipalityName.value))

const municipalities = computed<GroupOptions[]>(() => {
  const options = focusMunicipalitiesStore.municipalityGroupOptionsMap

  // 'Algemeen' should be the first option
  return [
    {
      label: 'Algemeen',
      values: [
        {
          name: 'general',
          label: 'Algemeen',
          category: 'Algemeen',
        },
      ],
    },
    ...options,
  ]
})

const signupReferenceExists = computed(
  () => !!activeSignupReferenceId.value || activeSignupReferenceId.value === 0,
)

const disableField = computed(
  () =>
    selectedSignupReference.value?.id &&
    [SignupReferenceUnknownId, SignupReferenceMiscId, SignupReferenceOopoehId].includes(
      selectedSignupReference.value?.id,
    ),
)

const currentSignupReferences = computed<OptionsMap[]>(() => {
  if (
    !signupReferenceStore.data ||
    !(camelCaseMunicipality.value in signupReferenceStore.data)
  ) {
    return []
  }

  return signupReferenceStore.data[camelCaseMunicipality.value]
    .map<OptionsMap>((reference: SignupReference) => {
      return {
        id: reference.id,
        name: reference.reference,
      }
    })
    .sort((a, b) => {
      if (!a.id || a.id === SignupReferenceMiscId) {
        return 1
      } else if (!b.id || b.id === SignupReferenceMiscId) {
        return -1
      }
      return a.name.localeCompare(b.name)
    })
})

const visibleSignupReferences = computed<OptionsMap[]>(() => {
  if (!signupReferenceStore.data) {
    return []
  }

  const generalOptions = clone(signupReferenceStore.data['general'])
  if (
    camelCaseMunicipality.value !== 'general' &&
    camelCaseMunicipality.value in signupReferenceStore.data
  ) {
    generalOptions.push(...signupReferenceStore.data[camelCaseMunicipality.value])
  }

  return generalOptions
    .filter((r) => r.isActive)
    .map<OptionsMap>((reference: SignupReference) => {
      return {
        id: reference.id,
        name: reference.reference,
      }
    })
    .sort((a, b) => {
      if (!a.id || a.id === SignupReferenceMiscId) {
        return 1
      } else if (!b.id || b.id === SignupReferenceMiscId) {
        return -1
      }
      return a.name.localeCompare(b.name)
    })
})

const signupReferencesNames = computed<string[]>(() => {
  return (
    signupReferenceStore.flatSignupReferences?.map((reference) =>
      reference.reference.toLowerCase(),
    ) ?? []
  )
})

const labelVisibleSignupReferences = computed(() => {
  if (municipalityName.value !== 'general') {
    return `"Gevonden via" opties zichtbaar in de website in gemeente ${startCaseMunicipality.value} (${visibleSignupReferences.value.length})`
  }

  return `"Gevonden via" opties zichtbaar in de website (${visibleSignupReferences.value.length})`
})

const selectedSignupReference = computed<SignupReference | undefined>(() => {
  if (signupReferenceExists.value) {
    return signupReferenceStore.flatSignupReferences?.find(
      (reference) => reference.id === activeSignupReferenceId.value,
    )
  }
})

const categoryOptions: OptionsMap[] = [
  {
    id: 'newspaper',
    name: 'Kranten',
  },
  {
    id: 'organisation',
    name: 'Zorg- en Welzijnsorganisaties',
  },
]

const { formProps, handleSubmit, resetForm, values, meta, handleReset, useField } =
  useTypedForm<Omit<UpdateSignupReferenceParams, 'municipality'>>({
    id: 'settings-signup-reference',
    schema: {
      reference: z.string(),
      translation: z.string(),
      category: z.string().nullable(),
      roleType: RoleTypeOptions,
      isActive: z.boolean(),
      activeFrom: nullableDayJSZod().refine(
        (val) => !val || val?.isAfter(dayjsUTC()),
        'Vul een datum in de toekomst in',
      ),
      activeUntil: nullableDayJSZod().refine(
        (val) => !val || val?.isAfter(dayjsUTC()),
        'Vul een datum in de toekomst in',
      ),
      houseNumber: z.string().nullable(),
      postalCode: z.string().nullable(),
      radius: z
        .number()
        .min(1)
        .nullable()
        .refine((val): boolean => {
          return (!val && !values.postalCode && !values.houseNumber) || !!val
        }),
    },
    initialValues: {
      category: null,
      roleType: RoleTypeEnum.Both,
      isActive: true,
      activeFrom: null,
      activeUntil: null,
      houseNumber: null,
      postalCode: null,
      radius: null,
    },
  })

watch(activeSignupReferenceId, (newValue) => {
  const signupReference = selectedSignupReference.value

  if (newValue && signupReference) {
    newSignupReferenceMode.value = false
    if (signupReference) {
      resetForm(removeKeysForResetForm(signupReference, ['id']))
    }
  }
})
watch(newSignupReferenceMode, (newValue) => {
  if (newValue) {
    activeSignupReferenceId.value = undefined
    resetForm({
      values: {
        reference: undefined,
        translation: undefined,
        isActive: true,
        category: null,
        roleType: RoleTypeEnum.Both,
        activeFrom: null,
        activeUntil: null,
        houseNumber: null,
        postalCode: null,
        radius: null,
      },
    })
  }
})

watch(municipalityName, (newValue) => {
  if (newValue) {
    activeSignupReferenceId.value = undefined
  }
})

watch(selectedSignupReference, (newValue: SignupReference | undefined) => {
  resetForm({
    values: {
      reference: newValue ? newValue.reference : '',
      translation: newValue ? newValue.translation : '',
    },
  })
})

const { value: activeFrom } = useField('activeFrom')
const { value: activeUntil } = useField('activeUntil')
const { value: isActive, resetField: resetIsActiveField } = useField('isActive')

watch(activeFrom, (newValue) => {
  if (newValue) {
    isActiveFieldDisabled.value = true
    isActive.value = !newValue.isAfter(dayjsUTC(), 'day')
  } else {
    isActiveFieldDisabled.value = !!activeUntil.value
    if (newSignupReferenceMode.value) {
      isActive.value = !activeUntil.value || activeUntil.value?.isAfter(dayjsUTC(), 'day')
    } else if (!!activeFrom.value) {
      resetIsActiveField()
    }
  }
})

watch(activeUntil, (newValue) => {
  if (newValue) {
    isActiveFieldDisabled.value = true
    isActive.value =
      newValue.isAfter(dayjsUTC(), 'day') && !activeFrom.value?.isAfter(dayjsUTC(), 'day')
  } else {
    isActiveFieldDisabled.value = !!activeFrom.value
    if (newSignupReferenceMode.value) {
      isActive.value = !activeFrom.value?.isAfter(dayjsUTC(), 'day')
    } else if (!!activeFrom.value) {
      resetIsActiveField()
    }
  }
})

const onSubmit = handleSubmit(async (values) => {
  if (
    newSignupReferenceMode.value &&
    signupReferencesNames.value.includes(values.reference.toLowerCase().trim())
  ) {
    toast.error('Deze referentie bestaat al')
    return
  }

  let confirmText: string
  if (newSignupReferenceMode.value) {
    if (municipalityName.value === 'general') {
      confirmText = `Voeg optie '${values.reference}' toe?`
    } else {
      confirmText = `Voeg optie '${values.reference}' toe aan de gemeente ${startCaseMunicipality.value}?`
    }
  } else {
    if (municipalityName.value === 'general') {
      confirmText = `Wijzig optie '${values.reference}'?`
    } else {
      confirmText = `Wijzig optie '${values.reference}' in de gemeente ${startCaseMunicipality.value}?`
    }
  }

  if (!confirm(confirmText)) {
    return
  }

  const params = {
    municipality: kebabCase(municipalityName.value),
    ...values,
  } satisfies UpdateSignupReferenceParams

  let result = false
  isLoading.value = true
  if (newSignupReferenceMode.value) {
    result = await signupReferenceStore.create(params)
  } else if (activeSignupReferenceId.value) {
    result = await signupReferenceStore.update(activeSignupReferenceId.value, params)
  }

  isLoading.value = false

  if (result && signupReferenceStore.data) {
    if (!(municipalityName.value in signupReferenceStore.data)) {
      signupReferenceStore.data[municipalityName.value] = []
    }
  }
})

const addressNames: Record<keyof CompleteAddress, string> = {
  houseNumber: 'houseNumber',
  postalCode: 'postalCode',
  street: 'street',
  city: 'city',
  municipality: 'municipality',
  borough: 'borough',
  neighborhood: 'neighborhood',
  district: 'district',
}
</script>

<template>
  <VCard title="Thuisgekookt gevonden via">
    <template #content>
      <div class="columns is-multiline is-align-items-flex-end">
        <div class="column is-one-third is-offset-2">
          <VField label="Kies een gemeente">
            <VMultiselectGroup
              v-model="municipalityName"
              :loading="isLoading"
              :options="municipalities"
              searchable
            />
          </VField>
        </div>

        <div class="column is-one-third">
          <VField :label="labelVisibleSignupReferences">
            <VMultiselect
              :loading="isLoading"
              :options="visibleSignupReferences"
              searchable
            />
          </VField>
        </div>

        <div class="column is-full mt-5 mb-4 is-divider"></div>

        <div class="column is-one-third is-offset-2 is-flex is-justify-content-center">
          <VAnimatedButton
            icon="fa-plus"
            icon-right="fa-plus"
            :color="newSignupReferenceMode ? 'success' : 'info'"
            override-color
            width="full"
            min-width="90%"
            :time="0.4"
            secondary-color="success"
            :disabled="newSignupReferenceMode"
            @click="newSignupReferenceMode = true"
          >
            Nieuwe 'Gevonden via' optie aanmaken
          </VAnimatedButton>
        </div>
        <div class="column is-one-third">
          <VField label="Bestaande 'Gevonden via' optie aanpassen">
            <VMultiselect
              v-model="activeSignupReferenceId"
              :options="currentSignupReferences"
              :loading="isLoading"
              allow-empty
              searchable
            />
          </VField>
        </div>

        <div class="column is-full mt-5 mb-4 is-divider"></div>
      </div>

      <div v-if="disableField" class="columns is-multiline">
        <div class="column is-full has-text-centered">
          <span class="is-size-3 has-text-weight-semibold has-text-warning">
            Deze optie mag niet aangepast worden
          </span>
        </div>
      </div>

      <FormWrapper
        v-else
        v-bind="formProps"
        class="columns is-multiline"
        error-column-class="column is-full"
        v-slot="{ k }"
      >
        <div class="column is-half">
          <FormInput
            :name="k.reference"
            label="'Gevonden via' optie naam"
            placeholder="'Gevonden via' optie"
          />
        </div>
        <div class="column is-half">
          <FormInput
            :name="k.translation"
            label="Vertaling"
            placeholder="Vertaalde optie"
          />
        </div>
        <div class="column is-half">
          <FormMultiselect
            :name="k.category"
            :options="categoryOptions"
            label="Categorie"
            allow-empty
          />
        </div>

        <div class="column is-half">
          <FormRadioIcon
            :name="k.roleType"
            :options="roleTypeOptions"
            label="Voor wie is deze optie bedoelt?"
          />
        </div>

        <div class="column is-one-third">
          <FormSwitchBlock
            :name="k.isActive"
            label="Is actief?"
            left-label="Nee"
            right-label="Ja"
            size="medium"
            :disabled="isActiveFieldDisabled"
            control-class="is-flex is-justify-content-center"
          />
        </div>

        <div class="column is-one-third">
          <FormDatePicker :name="k.activeFrom" label="Actief vanaf" />
        </div>
        <div class="column is-one-third">
          <FormDatePicker :name="k.activeUntil" label="Actief tot" />
        </div>

        <div class="column is-full">
          <p class="is-address-title has-text-centered">
            'Gevonden via' rond adres beschikbaar maken
          </p>
          <div class="columns is-justify-content-center">
            <div class="column is-one-third">
              <FormInput
                :name="k.radius"
                label="Maximale afstand tot adres (in meters)"
                type="number"
              />
            </div>
            <div class="column is-two-thirds">
              <div class="columns is-multiline">
                <FormAddress :names="addressNames" fake-labels />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-full mt-5 mb-4 is-divider"></div>

        <div class="column is-offset-2 is-one-third is-flex is-justify-content-center">
          <ResetButton
            :disabled="!meta.dirty"
            :loading="isLoading"
            :time="0.7"
            @reset="handleReset"
          />
        </div>
        <div class="column is-one-third is-flex is-justify-content-center">
          <VButton
            :icon-right="SaveIcon"
            color="success"
            width="full"
            :disabled="!meta.dirty"
            :loading="isLoading"
            @click.prevent="onSubmit"
          >
            Opslaan
          </VButton>
        </div>
      </FormWrapper>
    </template>
  </VCard>
</template>

<style lang="scss" scoped>
.is-address-title {
  font-family: var(--font) sans-serif;
  color: var(--light-text) !important;
  font-weight: 700;
}
</style>
