<script setup lang="ts">
import { computed } from 'vue'
import { useDarkMode } from '/@src/stores/darkmode'
import { devIsInProdEnvironment } from '/@src/utils/entity-checks'

type NavBarLogoProps = {
  color?: string
}

const props = defineProps<NavBarLogoProps>()

const darkMode = useDarkMode()

const getColor = (defaultColor: string) => {
  return props.color ? props.color : defaultColor
}

const getPanColor = computed(() => {
  if (devIsInProdEnvironment.value) {
    return '#151515'
  } else if (darkMode.isDark) {
    return '#ABABAB'
  } else {
    return '#151515'
  }
})
</script>

<template>
  <RouterLink :to="{ name: '/' }" class="navbar-item">
    <svg
      id="Layer_1"
      viewBox="0 0 651.9 651.9"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      overflow="visible"
      xml:space="preserve"
    >
      <path
        :fill="getColor('#EFD7A0')"
        d="M65.1,241.3c3.9-1.3,7.9-2.6,11.9-3c5.5-0.5,6.8,2.7,10.6,6c4.4,3.9,0.9,7.4-1.4,11.8
	c-2.9,5.4-5.3,11.2-6.8,17c-0.8,2.9-1.3,6-2.5,8.8c-1.2,2.8-3.3,5.1-5.2,7.5c-3.3,4-6.2,6.5-11.1,8.5c-1.7,0.7-3.9,2.2-5.6,2.4
	c-2.2,0.2-2.8-1.7-4.4-3.2c-5.1-4.8-7.7-12.4-8.4-19.3C40.5,262.9,48.7,247,65.1,241.3z"
      />
      <path
        :fill="getPanColor"
        d="M21.8,506c0.6-24.6,42.4-45.7,61-52.5c22.4-8.1,46.5-15,70.8-16.3c30-1.5,59.6-9.8,89.5-10
	c28.9-0.2,54.1,3.9,80.7,14.9c3.6,1.5,8.7,3.8,12.5,4c7.8,0.4,8.9-2.6,15.5-8.5c22.1-19.4,50.2-31.5,77.8-40.9
	c27.3-9.2,54.7-14.7,81.9-24.3c12-4.2,30.9-0.9,17.1,14.8c-16.7,18.9-41.8,21.4-64.8,26.1c-28.2,5.7-48.9,22.6-72.7,37.8
	c-9.6,6.1-16.9,13.2-24.8,21.2c-7,7-11.2,5.5-19.5,7.1c-11.4,2.2-18.3,5.7-22.5,16.2c-4.7,11.5-9.9,21.3-18.8,30.1
	c-22.1,21.9-58.4,31.2-87.3,40.3c-26.2,8.3-53.8,6-80.4,5.9c-20.2-0.1-48.8,0.1-66-11.4c-15.7-10.5-24.3-28.2-37.8-40.4
	C30.7,517.4,25.7,515.6,21.8,506z"
      />
      <path
        :fill="getColor('#08634B')"
        d="M207,48.6c-0.7-1-0.9-5.5,0-6.4c1.5-1.6,2.6,3.1,3.5,4.2c2.5-0.5,3.9-1.9,6.6-1.3c4.4,1,9,3.1,13.2,4.7
	c4.9,1.8,10.1,3.5,14.8,5.7c2.4,1.1,4.5,2.8,6.9,3.9c2.5,1.2,4.8,2,7,3.6c2.5,1.8,5.3,3.4,7.7,5.3c2.3,1.8,4.3,4,6.6,5.7
	c2.5,1.8,5.6,2.4,8,4.3c2.1,1.6,3,2.7,5.4,3.9c5.6,2.8,9.8,8,15,11.4c5.1,3.4,10.2,7,15.1,10.8c3.2,2.5,7.3,4.8,10.1,7.6
	c0.9,1,1.5,2.2,2.5,3.2c1.7,1.6,3.7,2.7,5.5,4c2.4,1.7,3.1,4.1,5.1,6.2c1.8,1.9,4.3,3.5,6.3,5.4c1.5,1.4,3.1,2.8,4.6,4.2
	c5.7,5.6,10.8,11.5,11.2,19.8c0.2,3,1.7,9-0.7,11.4c-3.9,3.9-9.9,1.2-14.1-0.2c-8-2.7-13.7-9.2-21.4-12.6c-2.7-1.2-3.5-3.5-5.7-5.2
	c-3.2-2.3-7.3-3.3-10.3-5.9c-9.3-7.9-18.3-16.4-28.8-22.7c-4.1-2.5-8-5.1-12.1-7.6c-2.9-1.8-5.8-3.4-8.6-5.5
	c-2.9-2.2-5.3-4.9-8.4-6.8c-2.3-1.4-5-2.2-7.4-3.5c-2.5-1.3-4.4-3.1-6.7-4.7c-2.3-1.6-4.9-3.1-6.9-5.1c-2-2-3.5-4.5-5.5-6.6
	c-3.7-3.9-7.5-7.4-11.6-10.9c-2.6-2.2-4.2-5-6.2-7.6c-2.1-2.7-3.4-5.4-2.5-8.8C205.3,51.5,205.7,50.4,207,48.6z"
      />
      <path
        :fill="getColor('#D4AE5A')"
        d="M281,171.9c10.8-4.7,10.6,18.1,18.2,22.1c3,1.6,5.8,1.1,8.4,3.6c0.9,0.9,0.6,3.3,1.5,4.2
	c2.4,2.6,5.2,3.5,8.3,5.2c8.1,4.6,12.2,13.6,9.2,23.2c-3.8,12-18,3.4-26.6,6.8c-3.2,6.7,8.2,6.5,12.2,7.7
	c14.8,4.2,29.8,7.1,43.9,14.7c5.1,2.7,13.3,8.2,13.8,14.8c0.1,1.6-2.6,2.1-2.6,3.9c0.1,2.4,2.6,4.5,2.6,6.6
	c-0.5,21.2-42.9-10.8-49.3-14.3c-4-2.2-23.3-12.1-25.4-4c-1.9,7.3,16.9,15.2,21,19.4c9,8.9,22.5,12.3,29,23.5
	c6.4,11.1,5.3,20-8.4,13.9c-1.6,4.6,2.4,8.2,3.5,12.4c1.9,7.5,0.2,5.6-4.7,7.7c-4.9,2.1-5.6-1.6-5.7,5.8c-0.1,7.2,6.4,12.3,7.4,19.3
	c1.7,10.7-6.2,19.5-13.4,26.4c-9.6,9.2-18.2,13-31.1,5.7c-3.8-2.2-8.8-6.8-9.9-11.3c-1.8-7.6,5.1-11.5,8.7-16.9
	c6.7-9.8,2.8-20.6,2.3-31.6c-0.2-3.7-2.1-16.4-8.6-16c-4.4,0.2-3.5,5.3-3.5,8.2c-0.1,6.4,0.2,7.3-2.6,13.2
	c-3.5,7.2-3.6,19.8-13.4,20.6c-16.5,1.4-21.2-19.2-18.3-31.1c3.1-12.8,15.3-17.6,19.1-29.8c1.9-6.1,5.7-26-4.1-28
	c-6.5-1.3-8.7,7.1-15,1c-7.9-7.7,1.3-10.4,4.6-16c3.5-5.9,3.5-13.6,10.3-18.2c2.6-1.8,9-1.5,10.9-3.6c3-3.3,0.3-10.3,0.2-14.1
	c-0.1-3.8,0.6-6.6-1.7-9.2c-2.8-3.1-8-1.3-10.2-6c-3.6-8,6.5-14,9-20.4c2.3-6,0.3-10.6,5-16.1C278.2,172.3,282.1,168.6,281,171.9z"
      />
      <path
        :fill="getColor('#C33D3A')"
        d="M258.9,164.6c-0.3-4-1.2-8.1-3.9-11.1c-2.7-2.9-7.5-4.2-10.7-1.9c-1.2,0.9-2.5,2.2-3.9,1.6
	c-0.6-0.3-1-0.9-1.4-1.4c-2.5-3.7-6.8-6.7-11.2-6.1c-3.1,0.5-5.9,2.6-8.6,4.3c0.7-4.9,1.3-9.9,2-14.8c0.2-1.8,0.8-3.9,2.5-4.5
	c2.3-0.7,5,1.9,6.9,0.4c0.9-0.7,1-2,0.7-3.1c-0.7-3.5-4.2-6.2-7.8-5.9c-2.7,0.2-5.1,1.9-6.7,4c-1.6,2.1-2.6,4.7-3.4,7.2
	c-1.1,3.4-4,11-4.4,17.1c-2.5-1.6-4.6-4-7.5-4.8c-6.1-1.8-12.2,4.3-18.4,3.2c-3-0.5-5.7-2.7-8.7-2.3c-4.1,0.5-6.1,5.2-7,9.2
	c-6.6,29.3,3.5,61.9,19.3,86.7c2.8,4.4,6.4,8.9,11.5,9.6c6.7,0.9,12.6-5.2,19.4-5.1c5,0.1,9.7,3.7,14.7,3c5.4-0.8,8.8-6.2,10.4-11.4
	c1.7-6,1.9-12.3,3-18.5C248.8,201.4,260.1,183.8,258.9,164.6z"
      />
      <path
        :fill="getColor('#DE6852')"
        d="M151.5,325.3c-10.2-3.2-20.6-5.4-30.7-9.2c-10.5-4-22.1-12.9-33.8-11.2c-10,1.4-19.5,0-26.7,7.9
	c-9.4,10.4-17.2,29.2-20.8,42.9c-2.7,10,0.9,17.5,6.2,25.5c4,6,5.1,14,8.9,19.5c4.9,7,14,6.4,21.3,6.1c15.3-0.5,30.5,1.4,46.1,0.6
	c26.4-1.2,52.6-0.7,78.7-1.9c16.8-0.8,26.5-9.1,36.2-20.6c3.4-4,5.9-6.2,5.2-11.5c-0.5-3.4-2.7-9.1-3.6-12.8
	c-2.7-11.2-15.6-14.3-25.7-17.9C194.1,336.2,171.3,335.1,151.5,325.3z"
      />
      <path
        :fill="getColor('#F5C455')"
        d="M204.4,83.2c1.8,4.5-3.9,7.1-4.9,10.9c-0.5,2.1,0.3,4.7,0.2,6.9c-0.1,4.1-1.2,8-3,11.7
	c-3.8,7.9-11.1,13.4-18.2,17.7c-9.2,5.6-19.4,6.2-29.5,3c-4-1.3-7.6-3.8-11.4-5.1c-1.9-0.6-4-0.1-5.7-1.2c-4.6-2.9-5.1-7.6-4.2-12.5
	c0.3-1.8,1.4-3,2-4.6c0.9-2.2,0.7-4.7,1.1-7c0.7-5,3.6-10.2,6.9-13.9c6.5-7.1,14.5-11.7,24-13.4c7.4-1.3,15.7-0.9,22.9,1.7
	c2.4,0.9,4.5,2.5,6.9,3.2c2.6,0.7,5.6,0.5,8.3,0.9C200.9,81.6,201.9,81.9,204.4,83.2z"
      />
      <path
        :fill="getColor('#EFD7A0')"
        d="M161.2,299.6c-3.4-5.1-6.8-10.2-8.8-15.8c-2.8-7.6,1.2-10.8,4.4-17.4c3.8-7.8,10.3-4.2,17.4-2.6
	c8.8,2,18,3.2,26.9,3.1c4.5,0,9-0.5,13.5,0.1c4.4,0.6,8.6,2.8,12.7,4.6c7,3.1,11.7,6.4,16.4,12.5c1.6,2.1,4.7,4.7,5.6,7.1
	c1.2,3-1.3,4.7-2.8,7.6c-4.8,9.1-14.7,15.7-24.2,19.4C201.6,326.2,175.7,320.7,161.2,299.6z"
      />
      <path
        :fill="getColor('#EFD7A0')"
        d="M361.6,233.5c-2.7,3-5.4,6-8.7,8.2c-4.4,3-7.1,1-11.8,0c-5.6-1.2-4.3-5.9-4.4-10.7c-0.1-6-0.9-12-2.4-17.7
	c-0.8-2.9-1.8-5.7-2.1-8.7c-0.3-2.9,0.4-6,0.9-8.8c0.8-5,2.2-8.5,5.3-12.5c1.1-1.4,2.3-3.8,3.6-4.8c1.7-1.3,3.2,0.1,5.3,0.6
	c6.6,1.6,12.4,6.9,16.3,12.3C372.1,203.5,372.8,220.9,361.6,233.5z"
      />
      <path
        :fill="getColor('#108063')"
        d="M120.3,271.2c-1.5-1.9-4-4.2-4.9-6.4c-0.9-2.4-0.4-4.7-1.8-7.1c-1.1-2-3.5-3.1-4.9-5.3
	c-1.4-2.3-1.8-4.9-3-7.2c-5-9.9-10.5-18.2-21.1-20.3c-3.7-0.7-7.8-3.1-11.4-3.2c-3.3-0.1-8,3.8-10.1,2.3c-4.8,1.7-5.7,6-11.7,4.6
	c-5.7-1.4-10.6-6.8-13.6-11.4c-4-6-4.8-9.5-3.4-16.8c1.3-6.4-0.8-12.4,0.8-18.6c1.8-7,5-11.2,8.5-17.2c1.7-2.9,2-6.7,3.5-9.7
	c2.1-3.9,6.1-7.6,9.7-10.3c7.8-6.1,15.2-8.3,24.8-7.4c5.1,0.5,9.1-1.2,14.1-2c4.2-0.7,10.2-2.4,14.6-1.2c4.7,1.2,7.9,6.7,12.2,7.9
	c5.1,1.4,8.9-1.6,14.2,1.6c4,2.5,7.5,5.7,9.9,9.8c2.9,4.9,4.7,10.6,4.9,16.3c0.3,6.7-2.1,8.5-6.5,13c-4.1,4.2-5.5,7.4-12.1,6.9
	c-2.5-0.2-4.3-2.1-6.9-0.6c-3.3,1.9-4.9,7.8-4.6,10.9c0.6,5.9,4,11.8,6.2,17.1c2.1,5.3,3.3,11.1,5.1,16.5c1.7,4.9,2.9,8.3,6.4,12.2
	c2.3,2.6,6,5.1,6.9,8.6c1.8,6.7-10.9,9.5-15.5,13.2C128.3,269.4,121.9,275.6,120.3,271.2z"
      />
    </svg>
  </RouterLink>
</template>

<style scoped lang="scss">
svg {
  width: 4rem;
  height: 4rem;
}

.is-dark a.navbar-item:hover {
  background-color: var(--dark-sidebar-light-2);
}
</style>
