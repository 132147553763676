<script setup lang="ts">
import type { VTabsItem } from '/@src/types/elements-ui'
import { useHead } from '@unhead/vue'
import { useRouteTabs } from '/@src/composable/useRouteTabs'

useHead({
  title: 'Dashboard | Thuisgekookt Admin',
})

definePage({
  meta: {
    requiresAdmin: true,
  },
})

const route = useRoute('/dashboard')
const router = useRouter()

const tabs: VTabsItem[] = [
  {
    label: 'Maaltijdzoekers',
    value: 'match-requests',
    to: {
      name: '/dashboard/',
    },
  },
  {
    label: 'Matchmakers',
    value: 'matchmakers',
    to: {
      name: '/dashboard/matchmakers',
    },
  },
  {
    label: 'Matchmaker KPIs',
    value: 'matchmaker-kpis',
    to: {
      name: '/dashboard/matchmakers-kpis',
    },
  },
  {
    label: 'Absenties',
    value: 'absences',
    to: {
      name: '/dashboard/absences',
    },
  },
]

const { activeTab } = useRouteTabs(tabs)

onMounted(() => {
  if (route.name === '/dashboard') {
    router.push({ name: '/dashboard/' })
  }
})
</script>

<template>
  <div>
    <VTabs v-model:selected="activeTab" :tabs="tabs" size="large" />

    <RouterView v-slot="{ Component }">
      <div class="page-content-inner">
        <component :is="Component" />
      </div>
    </RouterView>
  </div>
</template>

<style scoped lang="scss"></style>
