import { UploadableFile } from '/@src/utils/file-list'
import type {
  SlackErrorReport,
  SlackFile,
  SlackFinishUploadResult,
  SlackPermalinkResult,
  SlackSendMessageResult,
  SlackStartFileUploadResult,
  SlackUser,
  SlackUserResult,
} from '/@src/types/slack'

const token = import.meta.env.VITE_SLACK_TOKEN
const isDev = import.meta.env.DEV

export function useSlack() {
  const channelId = isDev ? 'C04V8D79QJY' : 'C0166G1GN80'

  const getListOfUsers = async (): Promise<SlackUser[] | false> => {
    const listFormData = new FormData()
    listFormData.append('token', `${token}`)

    const result = await fetch('https://slack.com/api/users.list', {
      method: 'POST',
      body: listFormData,
    })
    const resultData = (await result.json()) as SlackUserResult
    if (!resultData.ok) {
      return false
    }
    return resultData.members
  }

  const uploadFile = async (file?: File): Promise<SlackFile | false> => {
    if (!file) {
      return false
    }

    const formData = new FormData()
    formData.append('token', token)
    formData.append('filename', file.name)
    formData.append('length', file.size.toString())

    const result = await fetch('https://slack.com/api/files.getUploadURLExternal', {
      method: 'POST',
      body: formData,
    })
    const resultData = (await result.json()) as SlackStartFileUploadResult

    if (!resultData.ok) {
      return false
    }

    const fileFormData = new FormData()
    fileFormData.append('token', token)
    fileFormData.append('channels', channelId)
    fileFormData.append('fileType', 'zip')
    fileFormData.append('file', file)
    fileFormData.append('filename', file.name)
    fetch(resultData.upload_url, {
      method: 'POST',
      body: fileFormData,
    })

    return {
      id: resultData.file_id,
      title: file.name,
    }
  }

  const completeFileUpload = async (
    sendMessageResult: SlackSendMessageResult,
    files: SlackFile[],
  ): Promise<boolean> => {
    const completeUploadFormData = new FormData()
    completeUploadFormData.append('token', token)
    completeUploadFormData.append('channel_id', sendMessageResult.channel)
    completeUploadFormData.append('thread_ts', sendMessageResult.ts)
    completeUploadFormData.append('files', JSON.stringify(files))

    const confirmFileUpload = await fetch(
      'https://slack.com/api/files.completeUploadExternal',
      {
        method: 'POST',
        body: completeUploadFormData,
      },
    )
    const confirmFileUploadResult =
      (await confirmFileUpload.json()) as SlackFinishUploadResult
    return confirmFileUploadResult.ok
  }

  const sendMessage = async (
    blocks: unknown[],
    descriptionData: SlackErrorReport,
  ): Promise<SlackSendMessageResult> => {
    const messageFormData = new FormData()
    messageFormData.append('token', `${token}`)
    messageFormData.append('channel', channelId)
    messageFormData.append('text', descriptionData.reproductionSteps ?? '')
    messageFormData.append('blocks', JSON.stringify(blocks))
    messageFormData.append('username', 'Buggie')
    messageFormData.append('icon_emoji', ':buggie:')

    const response = await fetch('https://slack.com/api/chat.postMessage', {
      method: 'POST',
      body: messageFormData,
    })
    return (await response.json()) as SlackSendMessageResult
  }

  const getPermalink = async (
    sendMessageResult: SlackSendMessageResult,
  ): Promise<SlackPermalinkResult | false> => {
    const permalinkFormData = new FormData()
    permalinkFormData.append('token', `${token}`)
    permalinkFormData.append('channel', sendMessageResult.channel)
    permalinkFormData.append('message_ts', sendMessageResult.ts)
    permalinkFormData.append('pretty', '1')

    const result = await fetch('https://slack.com/api/chat.getPermalink', {
      method: 'POST',
      body: permalinkFormData,
    })
    return (await result.json()) as SlackPermalinkResult
  }

  const postMessage = async (
    content: File,
    screenshot: UploadableFile | null,
    descriptionData: SlackErrorReport,
    routePath: string,
    userId?: string,
  ): Promise<SlackPermalinkResult | false> => {
    // Reference
    // https://stackoverflow.com/questions/59939261/send-multiple-files-to-slack-via-api
    const blocks = [
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Link:*\n${routePath}`,
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Reproductie stappen:*\n${descriptionData.reproductionSteps ?? ''}`,
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Verwachte uitkomst:*\n${descriptionData.expectedOutcome ?? ''}`,
          },
        ],
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Echte uitkomst:*\n${descriptionData.actualOutcome ?? ''}`,
          },
        ],
      },
    ]

    if (userId) {
      blocks.push({
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `Verstuurd door <@${userId}>`,
          },
        ],
      })
    }

    const files: SlackFile[] = []
    const zipFileUpload = await uploadFile(content)

    if (!zipFileUpload) {
      return false
    }
    files.push(zipFileUpload)

    const screenshotUpload = await uploadFile(screenshot?.file)
    if (screenshot && !screenshotUpload) {
      return false
    } else if (screenshotUpload) {
      files.push(screenshotUpload)
    }

    const resultData = await sendMessage(blocks, descriptionData)
    if (!resultData.ok) {
      return false
    }

    const completeFileUploadResult = await completeFileUpload(resultData, files)
    if (!completeFileUploadResult) {
      return false
    }

    return await getPermalink(resultData)
  }

  return {
    postMessage,
    getListOfUsers,
  }
}
