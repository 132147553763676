<script setup lang="ts">
definePage({
  meta: {
    requiresAdmin: true,
    roles: ['finance', 'strategy'],
  },
})

const route = useRoute()
const router = useRouter()

watch(
  () => route.name,
  (newValue) => {
    if (newValue === '/donations') {
      router.replace({
        name: '/donations/',
      })
    }
  },
  { immediate: true },
)
</script>

<template>
  <div>
    <RouterView v-slot="{ Component }">
      <div class="page-content-inner">
        <component :is="Component" />
      </div>
    </RouterView>
  </div>
</template>
