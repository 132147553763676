import { acceptHMRUpdate, defineStore } from 'pinia'
import { useApi } from '/@src/composable/useApi'
import type {
  ActanAbsence,
  AdminUser,
  AdminUserEnpsAnswerParams,
  AdminUserEnpsAnswerResult,
  AdminUserFieldParams,
  AdminUserId,
  AminUserAutomaticUpdateParams,
  UpdateAdminUserParams,
  UpdateAdminUserWorkDaysParams,
} from '/@src/types/admin-users'
import { TrafficLight } from '/@src/types/admin-users'
import type { ApiResponse, MaybeArray } from '/@src/types/utils'
import { rejectWebSocketUpdate, resolveWebSocketUpdate } from '/@src/utils/webSockets'
import type { UserId } from '/@src/types/users'
import { UserRoleEnum } from '/@src/types/users'
import dayjs, { Dayjs } from 'dayjs'
import type { MaybeRefOrGetter } from 'vue'
import { undefinedUserId } from '/@src/models/users'
import { hasRole } from '/@src/utils/helpers'
import { toast } from 'vue-sonner'

const api = useApi()

interface State {
  adminUser?: AdminUser

  helpdeskers: AdminUser[]
  matchmakers: AdminUser[]
  exMatchmakers: AdminUser[]
  impactAccelerators: AdminUser[]
  developers: AdminUser[]
  misc: AdminUser[]
}

export const useAdminUsersStore = defineStore('adminUsers', {
  state: (): State => ({
    adminUser: undefined,

    matchmakers: [],
    helpdeskers: [],
    impactAccelerators: [],
    developers: [],
    exMatchmakers: [],
    misc: [],
  }),
  actions: {
    async updateAdminUsers(adminUser: AdminUser) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/admin-users/update`, adminUser)
          .then(resolveWebSocketUpdate(resolve, 'Account succesvol geüpdate!'))
          .catch(rejectWebSocketUpdate(resolve, 'Account updaten mislukt.'))
      })
    },

    async updateFieldAutomatically(payload: AminUserAutomaticUpdateParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/admin-users/update/automatic`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Account succesvol geüpdate!'))
          .catch(rejectWebSocketUpdate(resolve, 'Account niet geüpdate.'))
      })
    },

    async updateField(payload: AdminUserFieldParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/admin-users/update/${payload.field}`, payload)
          .then(resolveWebSocketUpdate(resolve, 'Account succesvol geüpdate!'))
          .catch(rejectWebSocketUpdate(resolve, 'Account updaten mislukt.'))
      })
    },

    async updateEnps(answer: AdminUserEnpsAnswerParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put('admin/admin-users/update/enps', answer)
          .then(resolveWebSocketUpdate(resolve, 'Antwoord opgeslagen!'))
          .catch(rejectWebSocketUpdate(resolve, 'Antwoord opslaan mislukt.'))
      })
    },

    async getEnpsAnswer() {
      return new Promise<AdminUserEnpsAnswerResult | null>((resolve) => {
        api
          .get<AdminUserEnpsAnswerResult>('admin/admin-users/enps-answer')
          .then((result) => {
            resolve(result.data)
          })
          .catch(() => {
            resolve(null)
          })
      })
    },

    async updateSpecificAdminUser(id: AdminUserId, params: UpdateAdminUserParams) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/admin-users/${id}/update`, params)
          .then(resolveWebSocketUpdate(resolve, 'Account is aangepast'))
          .catch(rejectWebSocketUpdate(resolve, 'Account updaten mislukt'))
      })
    },

    async updateSpecificAdminUserWorkdays(
      id: AdminUserId,
      params: UpdateAdminUserWorkDaysParams,
    ) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/admin-users/${id}/update/workdays`, params)
          .then(resolveWebSocketUpdate(resolve, 'Account is aangepast'))
          .catch(rejectWebSocketUpdate(resolve, 'Account updaten mislukt'))
      })
    },

    async updateSpecificAdminUserLockWorkdays(id: AdminUserId, lockWorkdays: boolean) {
      return new Promise<boolean>((resolve) => {
        api
          .put(`admin/admin-users/${id}/update/lock-workdays`, { lockWorkdays })
          .then(resolveWebSocketUpdate(resolve, 'Account is aangepast'))
          .catch(rejectWebSocketUpdate(resolve, 'Account updaten mislukt'))
      })
    },

    async getAbsences(startDate: Dayjs, endDate: Dayjs) {
      return new Promise<ActanAbsence[] | false>((resolve) => {
        api
          .post<ApiResponse<ActanAbsence[]>>('admin/admin-users/absences', {
            startDate,
            endDate,
          })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch(() => {
            toast.error('Absenties ophalen mislukt')
            resolve(false)
          })
      })
    },
  },
  getters: {
    hasRole:
      (state: State) =>
      (roleType: MaybeArray<UserRoleEnum>): boolean => {
        if (!state.adminUser) {
          return false
        }

        if (!Array.isArray(roleType)) {
          roleType = [roleType]
        }

        return roleType.some((r) => hasRole(state.adminUser!, r))
      },

    getAdminUserUnsafe:
      (state: State) =>
      (idRef: MaybeRefOrGetter<UserId | null | undefined>): AdminUser | undefined => {
        const id = toValue(idRef)

        if (!id) {
          return undefined
        }

        const allAdminUsers = [
          ...state.helpdeskers,
          ...state.matchmakers,
          ...state.exMatchmakers,
          ...state.impactAccelerators,
          ...state.misc,
        ]

        return allAdminUsers.find((user) => user.userId === id)
      },

    getDeveloper:
      (state: State) =>
      (id: AdminUserId): AdminUser | undefined => {
        return state.developers.find((user) => user.id === id)
      },

    isMatchmaker:
      (state: State) =>
      (idRef: MaybeRefOrGetter<number | string>): boolean => {
        const id = toValue(idRef)
        return (
          state.matchmakers.findIndex((matchmaker) => matchmaker.userId === id) !== -1
        )
      },
    isExMatchmaker:
      (state: State) =>
      (idRef: MaybeRefOrGetter<number | string>): boolean => {
        const id = toValue(idRef)
        return (
          state.exMatchmakers.findIndex((matchmaker) => matchmaker.userId === id) !== -1
        )
      },
    isMatchmakerOrExMatchmaker:
      (state: State) =>
      (idRef: MaybeRefOrGetter<number | string>): 'matchmaker' | 'ex' | false => {
        const id = toValue(idRef)
        if (!!state.matchmakers.findIndex((matchmaker) => matchmaker.userId === id)) {
          return 'matchmaker'
        } else if (
          !!state.exMatchmakers.findIndex((matchmaker) => matchmaker.userId === id)
        ) {
          return 'ex'
        }

        return false
      },

    getMatchmaker:
      (state: State) =>
      (idRef: MaybeRefOrGetter<number | string | null | undefined>): AdminUser => {
        let id = toValue(idRef)

        if (typeof id === 'string') {
          id = parseInt(id)
        }

        const matchmaker = state.matchmakers.find(
          (matchmaker) => matchmaker.userId === id,
        )

        if (matchmaker) {
          return matchmaker
        }

        const exMatchmaker = state.exMatchmakers.find(
          (matchmaker) => matchmaker.userId === id,
        )
        if (exMatchmaker) {
          return exMatchmaker
        }

        return {
          id: -1 as AdminUserId,
          userId: undefinedUserId,
          firstname: 'Onbekend',
          lastname: 'Onbekend',
          avatar: '',
          workdays: {
            matchmaker: [1, 2, 3, 4, 5],
            helpdesker: [],
            impactAccelerator: [],
            marketing: [],
            misc: [],
          },
          lockWorkdays: false,
          roles: [{ role: UserRoleEnum.Matchmaker }],
          birthdate: dayjs('1981-02-09'),
          maxOpen: 0,
          maxSuggested: 0,
          municipalities: [],
          favoriteAnimal: null,
          morningAvailability: TrafficLight.Green,
          afternoonAvailability: TrafficLight.Green,
          openForQuestions: true,
          emojiOfTheDay: null,
          awayFrom: null,
          awayUntil: null,
          leadUserId: null,
          targetModifier: 1,
        }
      },

    getMatchmakerUnsafe:
      (state: State) =>
      (idRef: MaybeRefOrGetter<number | string>): AdminUser | undefined => {
        let id = toValue(idRef)

        if (typeof id === 'string') {
          id = parseInt(id)
        }

        const matchmaker = state.matchmakers.find(
          (matchmaker) => matchmaker.userId === id,
        )
        if (matchmaker) {
          return matchmaker
        }

        const exMatchmaker = state.exMatchmakers.find(
          (matchmaker) => matchmaker.userId === id,
        )
        if (exMatchmaker) {
          return exMatchmaker
        }

        return undefined
      },
  },
})

/**
 * Pinia supports Hot Module replacement, so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAdminUsersStore, import.meta.hot))
}
