<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import type { OptionsMap, VTableColumns } from '/@src/types/elements-ui'
import { clone } from 'lodash'
import { useModal } from '/@src/composable/useModal'
import { useBugsStore } from '/@src/stores/bugs'
import {
  type Bug,
  type BugId,
  BugPriority,
  bugPriorityColor,
  bugPriorityIcon,
  BugSortOption,
  BugStatus,
} from '/@src/types/bugReport'
import { formatDate } from '/@src/utils/date-formatter'
import { toast } from 'vue-sonner'
import type { WebSocketsModelReply } from '/@src/types/webSockets'
import { updateModel } from '/@src/utils/webSockets'
import { useWebSocketModel } from '/@src/composable/useWebSocket'
import { StopIcon } from '/@src/models/standardIcons'
import { type UserId } from '/@src/types/users'
import { useAdminUsersStore } from '/@src/stores/adminUsers'

useHead({
  title: 'Bug Report | Thuisgekookt Admin',
})

definePage({
  meta: {
    roles: 'developer',
  },
})

const bugStore = useBugsStore()
const adminUserStore = useAdminUsersStore()

const bug = ref<Bug>()
const isLoading = ref(false)
const sortingPreference = ref<BugSortOption>(BugSortOption.Date)
const assigneePreferenceId = ref(-1)

const sortingOptions = ref<OptionsMap<BugSortOption>[]>([
  { id: BugSortOption.Date, name: 'Datum (nieuw - oud)' },
  { id: BugSortOption.Priority, name: 'Prioriteit (hoog - laag)' },
  { id: BugSortOption.Developer, name: 'Developer' },
])

const updateBug = async (event: WebSocketsModelReply<Bug>) => {
  const currentBug = bugStore.searchResults.find((bug) => {
    return bug.id === event.model.id
  })

  if (currentBug) {
    updateModel(currentBug, event.model)
  } else {
    toast.error('Bug niet geüpdate.')
  }
}

const { setModels } = useWebSocketModel<Bug>({
  baseChannel: 'tg-admin-channel-bug',
  event: '.BugUpdated',
  callback: updateBug,
})

const assigneeOptions = computed(() =>
  adminUserStore.developers.map((developer) => {
    return {
      id: developer.id,
      name: developer.firstname,
    }
  }),
)

const sortedResults = computed<Bug[]>(() => {
  let results = clone(bugStore.searchResults)

  if (sortingPreference.value === BugSortOption.Date) {
    results = results.sort((resultA, resultB) =>
      resultB.createdAt.toISOString().localeCompare(resultA.createdAt.toISOString()),
    )
  } else if (sortingPreference.value === BugSortOption.Priority) {
    results = results = results.sort((resultA, resultB) => {
      if (resultA.priority === BugPriority.High) {
        return -1
      } else if (resultB.priority === BugPriority.High) {
        return 1
      } else if (resultA.priority === BugPriority.Medium) {
        return -1
      } else if (resultB.priority === BugPriority.Medium) {
        return 1
      } else if (resultA.priority === BugPriority.Low) {
        return -1
      } else if (resultB.priority === BugPriority.Low) {
        return 1
      } else {
        return 0
      }
    })
  } else if (sortingPreference.value === BugSortOption.Developer) {
    if (assigneePreferenceId.value !== -1) {
      results = results.filter(
        (result) => result.developerId === assigneePreferenceId.value,
      )
    } else {
      results = sortedResults.value
    }
  }

  return results
})

const getUserFirstName = (userId: UserId) => {
  const user = adminUserStore.getAdminUserUnsafe(userId)

  return user?.firstname ?? 'Onbekend'
}

const {
  modalIsOpen: errorReportingModalIsOpen,
  openModal: openErrorReportingModal,
  closeModal: closeErrorReportingModal,
} = useModal()

const {
  modalIsOpen: bugReportModalIsOpen,
  openModal: openBugReportModal,
  closeModal: closeBugReportModal,
} = useModal({
  open: (selectedBug: Bug) => {
    bug.value = selectedBug
  },
  close: () => {
    bug.value = undefined
  },
})

const searchBug = async () => {
  isLoading.value = true
  await bugStore.searchBug()
  isLoading.value = false
}

const updatePriorityFieldAutomatic = async (bugId: BugId) => {
  await bugStore.updatePriorityFieldAutomatically(bugId)
}

watchEffect(() => {
  setModels(bugStore.searchResults)
})

onMounted(async () => {
  await bugStore.searchBug()
})

type ColumnKeys =
  | 'id'
  | 'createdAt'
  | 'user'
  | 'developer'
  | 'priority'
  | 'status'
  | 'actions'

const nonValidBug = [BugStatus.WontFix, BugStatus.NoBug, BugStatus.Cancelled]
const columns: VTableColumns<ColumnKeys, Bug> = [
  {
    key: 'id',
    label: 'Bug Id',
    onClick: (bug: Bug) => openBugReportModal(bug),
  },
  {
    key: 'createdAt',
    label: 'Datum van indiening',
    onClick: (bug: Bug) => openBugReportModal(bug),
    transform: (bug: Bug) => formatDate(bug.createdAt),
  },
  {
    key: 'user',
    label: 'Verstuurd door',
    onClick: (bug: Bug) => openBugReportModal(bug),
    transform: (bug: Bug) => getUserFirstName(bug.userId),
  },
  {
    key: 'developer',
    label: 'Developer',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'priority',
    label: 'Prioriteit',
    onClick: (bug: Bug) =>
      !nonValidBug.includes(bug.status) && updatePriorityFieldAutomatic(bug.id),
    iconColor: (bug: Bug) =>
      nonValidBug.includes(bug.status) ? 'info' : bugPriorityColor[bug.priority],
    iconOnly: (bug: Bug) =>
      nonValidBug.includes(bug.status) ? StopIcon : bugPriorityIcon[bug.priority],
  },
  {
    key: 'actions',
    label: 'Link naar Slack bericht',
  },
] as const
</script>

<template>
  <div>
    <h3 class="title is-5 my-4">Bugs</h3>

    <div class="columns is-multiline">
      <GeneralFilters v-model="bugStore.searchParams" @search="searchBug" />

      <GeneralFilterButtons
        :loading="isLoading"
        @reset="bugStore.resetFilter"
        @search="searchBug"
      >
        <template #button>
          <VButton
            color="success"
            :loading="isLoading"
            icon-left="fa-plus"
            width="full"
            @click="openErrorReportingModal"
            @keyup="openErrorReportingModal"
          >
            Bug indienen
          </VButton>
        </template>
      </GeneralFilterButtons>
    </div>

    <VTable
      :columns="columns"
      :data="sortedResults"
      :item-size="64"
      :loading="isLoading"
      automatic-virtual
      show-no-results
    >
      <!-- HEADER  -->
      <template #toolbar-left>
        <p class="subtitle mb-5">Filters</p>
        <div class="columns">
          <div class="column">
            <VSelect v-model="sortingPreference" :options="sortingOptions" />
          </div>
          <div v-if="sortingPreference === BugSortOption.Developer" class="column">
            <VSelect v-model="assigneePreferenceId" :options="assigneeOptions" />
          </div>
        </div>
      </template>

      <template #toolbar-right>
        <BugReportTableLegend />
      </template>

      <!--  TABLE  -->
      <template #body-cell="{ row, column }">
        <template v-if="column.key === 'developer'">
          <DeveloperSelector :id="row.id" :developer-id="row.developerId" />
        </template>

        <template v-else-if="column.key === 'status'">
          <BugStatusSelector :bug-id="row.id" :bug-status="row.status" />
        </template>

        <template v-else-if="column.key === 'actions'">
          <a
            v-if="row.linkToSlackMessage"
            :href="row.linkToSlackMessage"
            target="_blank"
            class="has-fullwidth"
          >
            <VButton outlined color="primary" width="full"> Open in Slack </VButton>
          </a>

          <VButton v-else color="danger" disabled width="full">
            Niet beschikbaar
          </VButton>
        </template>
      </template>
    </VTable>

    <!--  MODALS  -->
    <BugReportModal
      v-if="bug"
      v-model:open="bugReportModalIsOpen"
      :bug="bug"
      @close="closeBugReportModal"
    />

    <ErrorReportingModal
      v-model:open="errorReportingModalIsOpen"
      @close="closeErrorReportingModal"
    />
  </div>
</template>

<style lang="scss"></style>
