<script setup lang="ts">
import type { VTabsItem } from '/@src/types/elements-ui'
import { useRouteTabs } from '/@src/composable/useRouteTabs'

definePage({
  meta: {
    requiresAdmin: true,
  },
})

type TabKey = 'today' | 'overview' | 'upcoming-reminders' | 'automatic-reminders'
const tabs: VTabsItem<TabKey>[] = [
  {
    label: 'Vandaag',
    value: 'today',
    to: { name: '/to-do/' },
  },
  {
    label: 'Overzicht',
    value: 'overview',
    to: { name: '/to-do/overview' },
  },
  {
    label: 'Binnenkort',
    value: 'upcoming-reminders',
    to: { name: '/to-do/upcoming-reminders' },
  },
  {
    label: 'Lijst van automatische herinneringen',
    value: 'automatic-reminders',
    to: { name: '/to-do/automatic-reminders' },
  },
]

const { activeTab } = useRouteTabs(tabs)
</script>

<template>
  <div>
    <VTabs v-model:selected="activeTab" :tabs="tabs" />

    <RouterView v-slot="{ Component }">
      <div class="page-content-inner">
        <component :is="Component" />
      </div>
    </RouterView>
  </div>
</template>

<style scoped lang="scss"></style>
