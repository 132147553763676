import {
  CancelledIcon,
  NoPreferenceIcon,
  WaitingListIcon,
} from '/@src/models/standardIcons'
import type { BulmaColor, FAIconName, VIconColor } from '/@src/types/elements-ui'
import {
  type MatchRequestDateFieldType,
  MatchRequestStatus,
  MatchRequestStatusCamelCase,
  MatchRequestWarmupOptions,
} from '/@src/types/matchRequests'

export const matchRequestStatusMap: Record<MatchRequestStatus, string> = {
  [MatchRequestStatus.New]: 'Nieuw',
  [MatchRequestStatus.WaitingList]: 'Wachtlijst',
  [MatchRequestStatus.NotVulnerable]: 'Niet kwetsbaar',
  [MatchRequestStatus.Intake]: 'Intake',
  [MatchRequestStatus.Forwarded]: 'Uitgezet',
  [MatchRequestStatus.FollowUp]: 'Nabellen',
  [MatchRequestStatus.OnHold]: 'On hold',
  [MatchRequestStatus.NoCookFound]: 'Geen kok gevonden',
  [MatchRequestStatus.DontFollowUp]: 'Niet opvolgen',
  [MatchRequestStatus.Cancelled]: 'Geannuleerd',
  [MatchRequestStatus.Suggested]: 'Voorgesteld',
  [MatchRequestStatus.NAEmailed]: 'NB - gemaild',
  [MatchRequestStatus.NACalled]: 'NB - bellen',
  [MatchRequestStatus.SampleMeal]: 'Proefmaaltijd',
  [MatchRequestStatus.FinishedAfterSuggested]: 'Afgerond na voorgesteld',
  [MatchRequestStatus.FinishedAfterSampleMeal]: 'Afgerond na proefmaaltijd',
  [MatchRequestStatus.Match]: 'Match',
  [MatchRequestStatus.EstimatedMatch]: 'Ingeschatte match',
  [MatchRequestStatus.MatchStopped]: 'Match gestopt',
}

export const matchRequestStatusIconMap: Record<MatchRequestStatus, FAIconName> = {
  [MatchRequestStatus.New]: 'fa-plus-circle',
  [MatchRequestStatus.Intake]: 'fa-address-book',
  [MatchRequestStatus.Forwarded]: 'fa-address-book',
  [MatchRequestStatus.FollowUp]: 'fa-address-book',
  [MatchRequestStatus.OnHold]: 'fa-address-book',
  [MatchRequestStatus.NoCookFound]: 'fa-times-circle',
  [MatchRequestStatus.DontFollowUp]: 'fa-times-circle',
  [MatchRequestStatus.Cancelled]: CancelledIcon,
  [MatchRequestStatus.Suggested]: 'fa-check-circle',
  [MatchRequestStatus.NAEmailed]: 'fa-exclamation-triangle',
  [MatchRequestStatus.NACalled]: 'fa-exclamation-triangle',
  [MatchRequestStatus.SampleMeal]: 'fa-check-circle',
  [MatchRequestStatus.Match]: 'fa-check-circle',
  [MatchRequestStatus.FinishedAfterSuggested]: 'fa-paperclip',
  [MatchRequestStatus.FinishedAfterSampleMeal]: 'fa-paperclip',
  [MatchRequestStatus.MatchStopped]: 'fa-stop-circle',
  [MatchRequestStatus.NotVulnerable]: 'fa-times-circle',
  [MatchRequestStatus.WaitingList]: WaitingListIcon,
  [MatchRequestStatus.EstimatedMatch]: 'fa-dice',
}

export const matchRequestStatusColorMap: Record<MatchRequestStatus, BulmaColor> = {
  [MatchRequestStatus.New]: 'danger',
  [MatchRequestStatus.Intake]: 'info',
  [MatchRequestStatus.Forwarded]: 'info',
  [MatchRequestStatus.FollowUp]: 'info',
  [MatchRequestStatus.OnHold]: 'warning',
  [MatchRequestStatus.NoCookFound]: 'warning',
  [MatchRequestStatus.DontFollowUp]: 'warning',
  [MatchRequestStatus.Cancelled]: 'warning',
  [MatchRequestStatus.Suggested]: 'success',
  [MatchRequestStatus.NAEmailed]: 'warning',
  [MatchRequestStatus.NACalled]: 'warning',
  [MatchRequestStatus.SampleMeal]: 'success',
  [MatchRequestStatus.Match]: 'success',
  [MatchRequestStatus.FinishedAfterSuggested]: 'link',
  [MatchRequestStatus.FinishedAfterSampleMeal]: 'link',
  [MatchRequestStatus.MatchStopped]: 'danger',
  [MatchRequestStatus.NotVulnerable]: 'danger',
  [MatchRequestStatus.WaitingList]: 'link',
  [MatchRequestStatus.EstimatedMatch]: 'success',
}

export const matchRequestStatusHexColorMap: Record<MatchRequestStatus, string> = {
  [MatchRequestStatus.New]: '#f5e6f9',
  [MatchRequestStatus.Intake]: '#a2d4fb',
  [MatchRequestStatus.Forwarded]: '#35c5b0',
  [MatchRequestStatus.FollowUp]: '#56b365',
  [MatchRequestStatus.OnHold]: '#fef3f9',
  [MatchRequestStatus.NoCookFound]: '#ce4d18',
  [MatchRequestStatus.DontFollowUp]: '#882222',
  [MatchRequestStatus.Cancelled]: '#bd6900',
  [MatchRequestStatus.Suggested]: '#96d3f8',
  [MatchRequestStatus.NAEmailed]: '#a68200',
  [MatchRequestStatus.NACalled]: '#7e9f29',
  [MatchRequestStatus.SampleMeal]: '#8c9610',
  [MatchRequestStatus.Match]: '#5faf58',
  [MatchRequestStatus.FinishedAfterSuggested]: '#d5daff',
  [MatchRequestStatus.FinishedAfterSampleMeal]: '#eae0fc',
  [MatchRequestStatus.MatchStopped]: '#b9d6fe',
  [MatchRequestStatus.NotVulnerable]: '#db073d',
  [MatchRequestStatus.WaitingList]: '#ffffff',
  [MatchRequestStatus.EstimatedMatch]: '#f7e9f9',
}

export const matchRequestCamelToKebabCaseMap: Record<
  MatchRequestStatusCamelCase,
  MatchRequestStatus
> = {
  [MatchRequestStatusCamelCase.New]: MatchRequestStatus.New,
  [MatchRequestStatusCamelCase.WaitingList]: MatchRequestStatus.WaitingList,
  [MatchRequestStatusCamelCase.NotVulnerable]: MatchRequestStatus.NotVulnerable,
  [MatchRequestStatusCamelCase.Intake]: MatchRequestStatus.Intake,
  [MatchRequestStatusCamelCase.Forwarded]: MatchRequestStatus.Forwarded,
  [MatchRequestStatusCamelCase.FollowUp]: MatchRequestStatus.FollowUp,
  [MatchRequestStatusCamelCase.OnHold]: MatchRequestStatus.OnHold,
  [MatchRequestStatusCamelCase.NoCookFound]: MatchRequestStatus.NoCookFound,
  [MatchRequestStatusCamelCase.DontFollowUp]: MatchRequestStatus.DontFollowUp,
  [MatchRequestStatusCamelCase.Cancelled]: MatchRequestStatus.Cancelled,
  [MatchRequestStatusCamelCase.Suggested]: MatchRequestStatus.Suggested,
  [MatchRequestStatusCamelCase.NAEmailed]: MatchRequestStatus.NAEmailed,
  [MatchRequestStatusCamelCase.NACalled]: MatchRequestStatus.NACalled,
  [MatchRequestStatusCamelCase.SampleMeal]: MatchRequestStatus.SampleMeal,
  [MatchRequestStatusCamelCase.FinishedAfterSuggested]:
    MatchRequestStatus.FinishedAfterSuggested,
  [MatchRequestStatusCamelCase.FinishedAfterSampleMeal]:
    MatchRequestStatus.FinishedAfterSampleMeal,
  [MatchRequestStatusCamelCase.Match]: MatchRequestStatus.Match,
  [MatchRequestStatusCamelCase.EstimatedMatch]: MatchRequestStatus.EstimatedMatch,
  [MatchRequestStatusCamelCase.MatchStopped]: MatchRequestStatus.MatchStopped,
}

export const matchRequestKebabToCamelCaseMap: Record<
  MatchRequestStatus,
  MatchRequestStatusCamelCase
> = {
  [MatchRequestStatus.New]: MatchRequestStatusCamelCase.New,
  [MatchRequestStatus.WaitingList]: MatchRequestStatusCamelCase.WaitingList,
  [MatchRequestStatus.NotVulnerable]: MatchRequestStatusCamelCase.NotVulnerable,
  [MatchRequestStatus.Intake]: MatchRequestStatusCamelCase.Intake,
  [MatchRequestStatus.Forwarded]: MatchRequestStatusCamelCase.Forwarded,
  [MatchRequestStatus.FollowUp]: MatchRequestStatusCamelCase.FollowUp,
  [MatchRequestStatus.OnHold]: MatchRequestStatusCamelCase.OnHold,
  [MatchRequestStatus.NoCookFound]: MatchRequestStatusCamelCase.NoCookFound,
  [MatchRequestStatus.DontFollowUp]: MatchRequestStatusCamelCase.DontFollowUp,
  [MatchRequestStatus.Cancelled]: MatchRequestStatusCamelCase.Cancelled,
  [MatchRequestStatus.Suggested]: MatchRequestStatusCamelCase.Suggested,
  [MatchRequestStatus.NAEmailed]: MatchRequestStatusCamelCase.NAEmailed,
  [MatchRequestStatus.NACalled]: MatchRequestStatusCamelCase.NACalled,
  [MatchRequestStatus.SampleMeal]: MatchRequestStatusCamelCase.SampleMeal,
  [MatchRequestStatus.FinishedAfterSuggested]:
    MatchRequestStatusCamelCase.FinishedAfterSuggested,
  [MatchRequestStatus.FinishedAfterSampleMeal]:
    MatchRequestStatusCamelCase.FinishedAfterSampleMeal,
  [MatchRequestStatus.Match]: MatchRequestStatusCamelCase.Match,
  [MatchRequestStatus.EstimatedMatch]: MatchRequestStatusCamelCase.EstimatedMatch,
  [MatchRequestStatus.MatchStopped]: MatchRequestStatusCamelCase.MatchStopped,
}

export const activeMatchRequestStatusesCamelcase = [
  MatchRequestStatusCamelCase.New,
  MatchRequestStatusCamelCase.WaitingList,
  MatchRequestStatusCamelCase.NACalled,
  MatchRequestStatusCamelCase.NAEmailed,
  MatchRequestStatusCamelCase.Intake,
  MatchRequestStatusCamelCase.Forwarded,
  MatchRequestStatusCamelCase.FollowUp,
  MatchRequestStatusCamelCase.Suggested,
  MatchRequestStatusCamelCase.SampleMeal,
]

export const matchedMatchRequestStatusesCamelcase = [
  MatchRequestStatusCamelCase.FinishedAfterSuggested,
  MatchRequestStatusCamelCase.FinishedAfterSampleMeal,
  MatchRequestStatusCamelCase.Match,
  MatchRequestStatusCamelCase.EstimatedMatch,
  MatchRequestStatusCamelCase.MatchStopped,
]

export const cancelledMatchRequestStatusesCamelcase = [
  MatchRequestStatusCamelCase.Cancelled,
  MatchRequestStatusCamelCase.OnHold,
  MatchRequestStatusCamelCase.NotVulnerable,
  MatchRequestStatusCamelCase.NoCookFound,
  MatchRequestStatusCamelCase.DontFollowUp,
]

export const noCooksFlagMatchRequestStatuses = [
  MatchRequestStatus.New,
  MatchRequestStatus.Intake,
  MatchRequestStatus.Forwarded,
  MatchRequestStatus.FollowUp,
  MatchRequestStatus.OnHold,
]

export const frequencyMap: Record<number, string> = {
  1: 'Eenmalig',
  2: 'Af en toe',
  3: 'Regelmatig',
}
export const canWarmupMap: Record<MatchRequestWarmupOptions, string> = {
  [MatchRequestWarmupOptions.NoPreference]: 'Maakt niet uit',
  [MatchRequestWarmupOptions.Can]: 'Kan opwarmen',
  [MatchRequestWarmupOptions.Cannot]: 'Kan niet opwarmen',
}

export const canWarmupIconMap: Record<MatchRequestWarmupOptions, FAIconName> = {
  [MatchRequestWarmupOptions.NoPreference]: NoPreferenceIcon,
  [MatchRequestWarmupOptions.Can]: 'fa-fire-burner',
  [MatchRequestWarmupOptions.Cannot]: 'fa-mug-hot',
}

export const canWarmupIconColorMap: Record<MatchRequestWarmupOptions, VIconColor> = {
  [MatchRequestWarmupOptions.NoPreference]: 'warning',
  [MatchRequestWarmupOptions.Can]: 'success',
  [MatchRequestWarmupOptions.Cannot]: 'danger',
}

export const activeMatchRequestsStatuses = [
  MatchRequestStatus.New,
  MatchRequestStatus.WaitingList,
  MatchRequestStatus.NAEmailed,
  MatchRequestStatus.NACalled,
  MatchRequestStatus.Intake,
  MatchRequestStatus.Forwarded,
  MatchRequestStatus.FollowUp,
  MatchRequestStatus.Suggested,
  MatchRequestStatus.SampleMeal,
]

export const matchMatchRequestsStatuses = [
  MatchRequestStatus.Match,
  MatchRequestStatus.EstimatedMatch,
]

export const stoppedMatchRequestStatuses = [
  MatchRequestStatus.NoCookFound,
  MatchRequestStatus.DontFollowUp,
  MatchRequestStatus.Cancelled,
  MatchRequestStatus.FinishedAfterSuggested,
  MatchRequestStatus.FinishedAfterSampleMeal,
  MatchRequestStatus.MatchStopped,
  MatchRequestStatus.NotVulnerable,
]

export const matchRequestDateFieldLabel: Record<MatchRequestDateFieldType, string> = {
  latest_contact_moment: 'Meest recente contactmoment',
  suggested_on: 'Voorgesteld op',
  sample_meal_on: 'Proefmaaltijd op',
}
