<script lang="ts" setup generic="T extends string = string">
import type {
  BulmaSize,
  ComponentClass,
  VTabsAlign,
  VTabsItem,
  VTabsType,
} from '/@src/types/elements-ui'
import { isArray } from 'lodash'
import { toValue } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

interface VTabsProps {
  tabs: VTabsItem<T>[]
  type?: VTabsType
  size?: BulmaSize
  tabsClass?: ComponentClass
  listClasses?: ComponentClass
  align?: VTabsAlign
  slider?: boolean
  slow?: boolean
  hide?: boolean
  group?: boolean
}

const props = withDefaults(defineProps<VTabsProps>(), {
  type: undefined,
  size: 'normal',
  tabsClass: '',
  listClasses: '',
  align: undefined,
})

// FIXME - the default is not explicit enough, rather have 'props.tabs[0].value'
const activeValue = defineModel<T | T[]>('selected', {
  required: false,
  default: '' as T,
})

const sliderClass = computed<string>(() => {
  let result = ''

  if (!props.slider) {
    return result
  }

  if (props.tabs.length === 2) {
    result = result + ' is-slider'
  }
  if (props.tabs.length === 3) {
    result = result + ' is-triple-slider'
  }
  if (!props.type) {
    result = result + ' is-squared'
  }

  return result
})

const visibleTabs = computed(() => props.tabs.filter((t) => !toValue(t.hidden)))

const toggle = (value: T): void => {
  if (isArray(activeValue.value)) {
    if (activeValue.value.includes(value)) {
      const idx = activeValue.value.findIndex((v) => v === value)
      activeValue.value.splice(idx, 1)
    } else {
      activeValue.value.push(value)
    }
  } else {
    activeValue.value = value
  }
}

const getTabClass = (tab: VTabsItem<T>): string | undefined => {
  if (
    activeValue.value === tab.value ||
    (isArray(activeValue.value) && activeValue.value.includes(tab.value))
  ) {
    const color = toValue(tab.color)
    if (color) {
      return `is-${color}`
    } else {
      return 'is-primary'
    }
  }

  return undefined
}
</script>

<template>
  <div :class="[sliderClass]" class="tabs-wrapper">
    <div v-if="!hide" class="tabs-inner">
      <div
        :class="[
          `is-${size}`,
          align === 'centered' && 'is-centered',
          align === 'right' && 'is-right',
          type === 'rounded' && !slider && 'is-toggle is-toggle-rounded',
          type === 'toggle' && 'is-toggle',
          type === 'boxed' && 'is-boxed',
          tabsClass,
        ]"
        class="tabs"
      >
        <slot name="pre-tabs"></slot>
        <ul :class="listClasses">
          <li
            v-for="(tab, key) in visibleTabs"
            :key="key"
            :class="[
              getTabClass(tab),
              toValue(tab.hidden) && 'is-hidden',
              toValue(tab.disabled) && 'is-disabled',
            ]"
          >
            <slot
              name="tab-link"
              v-bind="{
                activeValue,
                tab,
                key,
                toggle,
              }"
            >
              <RouterLink
                v-if="toValue(tab.to)"
                :to="toValue(tab.to)! as RouteLocationRaw"
                @click.prevent="() => toggle(tab.value)"
                @keydown.prevent.enter="() => toggle(tab.value)"
              >
                <VIcon
                  v-if="!!toValue(tab.icon)"
                  :icon="toValue(tab.icon)!"
                  :icon-pack="toValue(tab.iconPack)"
                />
                <span>
                  <slot
                    name="tab-link-label"
                    v-bind="{
                      activeValue,
                      tab,
                      key,
                      toggle,
                    }"
                  >
                    {{ toValue(tab.label) }}
                  </slot>
                </span>
              </RouterLink>

              <a
                v-else
                @click.prevent="() => toggle(tab.value)"
                @keydown.prevent.enter="() => toggle(tab.value)"
              >
                <VIcon
                  v-if="!!toValue(tab.icon)"
                  :icon="toValue(tab.icon)!"
                  :icon-pack="toValue(tab.iconPack)"
                />
                <span>
                  <slot
                    name="tab-link-label"
                    v-bind="{
                      activeValue,
                      tab,
                      key,
                      toggle,
                    }"
                  >
                    {{ toValue(tab.label) }}
                  </slot>
                </span>
              </a>
            </slot>
          </li>
          <li v-if="sliderClass" class="tab-naver"></li>
        </ul>
      </div>
    </div>

    <div v-if="$slots['tab']" class="tab-content is-primary">
      <Transition
        v-if="!group && !isArray(activeValue)"
        :name="slow ? 'fade-slow' : 'fade-fast'"
        mode="out-in"
      >
        <slot name="tab" :active-value="activeValue" />
      </Transition>
      <TransitionGroup
        v-else-if="!isArray(activeValue)"
        :name="slow ? 'fade-slow' : 'fade-fast'"
      >
        <slot name="tab" :active-value="activeValue" />
      </TransitionGroup>
      <slot v-else name="tab" :active-value="activeValue" />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
