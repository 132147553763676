<script setup lang="ts">
import { useMonthlyTargetsStore } from '/@src/stores/monthlyTargets'
import { useModal } from '/@src/composable/useModal'
import type { PartialMonthlyTarget } from '/@src/types/monthly-targets'
import { dayjsUTC, formatMonthlyTarget } from '/@src/utils/date-formatter'

definePage({
  meta: {
    roles: 'manager',
  },
})

const monthlyTargetStore = useMonthlyTargetsStore()

const selectedTarget = ref<PartialMonthlyTarget>()

const currentYear = ref<number>(dayjsUTC().year())

const currentTargets = computed(() =>
  monthlyTargetStore.getMonthlyTargets(currentYear.value),
)

const totalGold = computed(() =>
  Object.values(currentTargets.value).reduce((acc, curr) => {
    return curr.goldValue + acc
  }, 0),
)

const totalSilver = computed(() =>
  Object.values(currentTargets.value).reduce((acc, curr) => {
    return curr.silverValue + acc
  }, 0),
)

const totalBronze = computed(() =>
  Object.values(currentTargets.value).reduce((acc, curr) => {
    return curr.bronzeValue + acc
  }, 0),
)

const {
  modalIsOpen,
  openModal: openModal,
  closeModal: closeModal,
} = useModal({
  open: (year: number, month: number) => {
    const existingTarget = currentTargets.value.find(
      (t) => t.year === year && t.month === month,
    )

    if (existingTarget) {
      selectedTarget.value = existingTarget
    } else {
      selectedTarget.value = {
        year: year,
        month: month,
        goldValue: undefined,
        silverValue: undefined,
        bronzeValue: undefined,
      }
    }
  },
  close: () => {
    selectedTarget.value = undefined
  },
})
</script>

<template>
  <div>
    <VCard title="Maandelijkse doelen">
      <template #toolbar-right>
        <VInput v-model="currentYear" type="number" min="2024" />
      </template>
      <template #content>
        <div class="columns is-multiline">
          <div class="column is-full">
            <VCard content-classes="columns">
              <template #content>
                <div class="column is-offset-2 is-one-third has-text-weight-bold">
                  Totaal doelen van jaar {{ currentYear }}:
                </div>
                <div
                  class="column is-one-third is-flex is-flex-direction-row is-align-items-center"
                >
                  <div class="mr-2">
                    <VIcon color="gold" icon="fa-medal" />
                    <span class="has-text-weight-bold">{{ totalGold }}</span>
                  </div>
                  <div class="mx-1">
                    <VIcon color="silver" icon="fa-medal" />
                    <span class="has-text-weight-bold">{{ totalSilver }}</span>
                  </div>
                  <div class="ml-2">
                    <VIcon color="bronze" icon="fa-medal" />
                    <span class="has-text-weight-bold">{{ totalBronze }}</span>
                  </div>
                </div>
              </template>
            </VCard>
          </div>

          <template v-for="month in 12" :key="`monthly-target-${month}`">
            <div class="column is-one-third">
              <VCard
                :title="formatMonthlyTarget({ year: currentYear, month: month })"
                top-right-icon
              >
                <template #icon>
                  <VIcon
                    :icon="currentTargets[month - 1] ? 'fa-edit' : 'fa-square-plus'"
                    size="large"
                    font-awesome-icon-size="2x"
                    @click="openModal(currentYear, month)"
                  />
                </template>
                <template #content>
                  <ul v-if="currentTargets[month - 1]" class="is-size-5">
                    <li>
                      <VIcon color="gold" icon="fa-medal" />{{
                        currentTargets[month - 1].goldValue
                      }}
                    </li>
                    <li>
                      <VIcon color="silver" icon="fa-medal" />{{
                        currentTargets[month - 1].silverValue
                      }}
                    </li>
                    <li>
                      <VIcon color="bronze" icon="fa-medal" />{{
                        currentTargets[month - 1].bronzeValue
                      }}
                    </li>
                  </ul>
                  <ul v-else class="is-size-5">
                    <li><VIcon color="gold" icon="fa-medal" />-</li>
                    <li><VIcon color="silver" icon="fa-medal" />-</li>
                    <li><VIcon color="bronze" icon="fa-medal" />-</li>
                  </ul>
                </template>
              </VCard>
            </div>
            <div v-if="month % 3 === 0" class="column is-divider is-full mb-0"></div>
          </template>
        </div>
      </template>
    </VCard>

    <MonthlyTargetModal
      v-if="selectedTarget"
      v-model:open="modalIsOpen"
      :target="selectedTarget"
      @close="closeModal"
    />
  </div>
</template>

<style scoped lang="scss"></style>
