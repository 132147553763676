<script setup lang="ts">
import { computed } from 'vue'
import { useWindowScroll } from '@vueuse/core'
import { useAuthStore } from '/@src/stores/auth'
import { devIsInProdEnvironment } from '/@src/utils/entity-checks'

export type NavbarTheme = 'default' | 'colored' | 'fade'

const props = withDefaults(
  defineProps<{
    theme?: NavbarTheme
  }>(),
  {
    theme: 'default',
  },
)

const authStore = useAuthStore()
const isLoggedInAs = computed(() => !!authStore.adminUser)

const { y } = useWindowScroll()

const isScrolling = computed(() => {
  return y.value > 30
})
</script>

<template>
  <div
    class="navbar-navbar"
    :class="[
      isScrolling && 'is-scrolled',
      props.theme === 'fade' && 'is-transparent',
      props.theme === 'colored' && 'is-colored',
      isLoggedInAs && 'is-colored has-background-danger',
      devIsInProdEnvironment && 'navbar-dev-prod',
    ]"
  >
    <div class="navbar-navbar-inner">
      <div class="left">
        <!-- Title slot -->
        <slot name="title">
          <h1 class="title is-5">Page Title</h1>
        </slot>
      </div>
      <div class="center is-hidden-mobile">
        <!-- Links slot -->
        <slot name="links">
          <div class="centered-links">
            <a href="/public" class="centered-link centered-link-toggle">
              <span>Homepage</span>
            </a>
          </div>
        </slot>
      </div>
      <div class="center is-hidden-tablet">
        <slot name="mobile-links" />
      </div>
      <div class="right is-hidden-mobile">
        <!-- Toolbar slot -->
        <slot name="toolbar"></slot>
      </div>
      <slot name="bottom-bar"></slot>
    </div>
  </div>

  <!-- Subnav slot -->
  <slot name="subnav"></slot>
</template>

<style lang="scss"></style>
