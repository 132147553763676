<script setup lang="ts">
import { computed } from 'vue'
import type { Color, LengthUnit } from '/@src/types/utils'
import type {
  FAIconPack,
  FAIconName,
  VIconColor,
  IconSize,
  ComponentClass,
} from '/@src/types/elements-ui'

type FontAwesomeIconSize = 'lg' | '2x'

interface IconProps {
  icon: FAIconName
  iconPack?: FAIconPack
  iconClass?: ComponentClass
  size?: IconSize
  fontAwesomeIconSize?: FontAwesomeIconSize
  color?: VIconColor
  textColor?: VIconColor
  text?: string
  flex?: boolean
  list?: boolean
  backgroundColor?: Color
  borderRadius?: LengthUnit
  disableIconClass?: boolean
  overrideClickable?: boolean
}

const props = withDefaults(defineProps<IconProps>(), {
  iconPack: 'fas',
  size: undefined,
  fontAwesomeIconSize: undefined,
  color: undefined,
  textColor: undefined,
  text: undefined,
  backgroundColor: undefined,
  borderRadius: undefined,
})

const computedIconSize = computed(() => {
  if (props.size) {
    return `is-${props.size}`
  }
  return undefined
})

const computedFontAwesomeIconSize = computed(() => {
  if (props.fontAwesomeIconSize) {
    return `fa-${props.fontAwesomeIconSize}`
  }
  return undefined
})

const computedIconColor = computed(() => {
  if (props.color) {
    return `has-text-${props.color}`
  }
  return undefined
})

const computedTextColor = computed(() => {
  if (props.textColor) {
    return `has-text-${props.textColor}`
  }
  return undefined
})

const computedBackground = computed(() => {
  return {
    'background-color': props.backgroundColor,
    'border-radius': props.borderRadius,
  }
})
</script>

<template>
  <div
    v-if="text && flex"
    :style="computedBackground"
    class="icon-text"
    :class="[
      textColor && computedTextColor,
      size && computedIconSize,
      $attrs.onClick && !overrideClickable && 'is-clickable',
    ]"
  >
    <span :class="disableIconClass && 'icon'">
      <i
        :class="[
          iconPack,
          icon,
          iconClass,
          color && computedIconColor,
          fontAwesomeIconSize && computedFontAwesomeIconSize,
          $attrs.onClick && !overrideClickable && 'is-clickable',
        ]"
      ></i>
    </span>
    <span class="ml-2">{{ text }}</span>
  </div>
  <span
    v-else-if="list && iconPack === 'fas'"
    :style="computedBackground"
    class="fa-li"
    :class="[
      textColor && computedTextColor,
      size && computedIconSize,
      $attrs.onClick && !overrideClickable && 'is-clickable',
    ]"
  >
    <i
      :class="[
        iconPack,
        icon,
        iconClass,
        color && computedIconColor,
        $attrs.onClick && !overrideClickable && 'is-clickable',
      ]"
    ></i>
  </span>
  <span
    v-else-if="text"
    :style="computedBackground"
    class="icon-text"
    :class="[
      textColor && computedTextColor,
      size && computedIconSize,
      $attrs.onClick && !overrideClickable && 'is-clickable',
    ]"
  >
    <span :class="disableIconClass && 'icon'">
      <i
        :class="[
          iconPack,
          icon,
          iconClass,
          color && computedIconColor,
          fontAwesomeIconSize && computedFontAwesomeIconSize,
          $attrs.onClick && !overrideClickable && 'is-clickable',
        ]"
      ></i>
    </span>
    <span class="ml-2">{{ text }}</span>
  </span>
  <span
    v-else
    :style="computedBackground"
    :class="[
      size && computedIconSize,
      !disableIconClass && 'icon',
      $attrs.onClick && !overrideClickable && 'is-clickable',
    ]"
  >
    <i
      :class="[
        iconPack,
        icon,
        iconClass,
        color && computedIconColor,
        fontAwesomeIconSize && computedFontAwesomeIconSize,
        $attrs.onClick && !overrideClickable && 'is-clickable',
      ]"
    ></i>
  </span>
</template>

<style scoped lang="scss"></style>
