<script setup lang="ts">
import { useWebSocket } from '/@src/composable/useWebSocket'
import type { SimpleWebSocketMessage } from '/@src/types/webSockets'
import { UserRoleEnum } from '/@src/types/users'
import { toast } from 'vue-sonner'
import { useAuthStore } from '/@src/stores/auth'
import { useGlobalsStore } from '/@src/stores/global'
import { useTasksStore } from '/@src/stores/tasks'
import type { MatchmakerStatisticsResult } from '/@src/types/dashboard'
import { useMonthlyTargetsStore } from '/@src/stores/monthlyTargets'
import defu from 'defu'
import type { TotalMonthlyTargets } from '/@src/types/monthly-targets'
import { useFocusMunicipalitiesStore } from '/@src/stores/focusMunicipalities'

const globalsStore = useGlobalsStore()
const authStore = useAuthStore()

const taskStore = useTasksStore()

const monthlyTargetStore = useMonthlyTargetsStore()
const focusMunicipalityStore = useFocusMunicipalitiesStore()

const lockdownModalIsOpen = ref(false)
const lockdownReason = ref<string>()
const lockdownAdmin = (message: { reason?: string }) => {
  lockdownModalIsOpen.value = true
  lockdownReason.value = message.reason
}

const websocketCallback = (message: SimpleWebSocketMessage) => {
  if (
    (!message.userId && authStore.hasRole(UserRoleEnum.Developer)) ||
    (message.userId && authStore.user?.id === message.userId)
  ) {
    toast.success(message.message)
  }
}

const updateMatchmakerStats = (stats: { statistics: MatchmakerStatisticsResult }) => {
  globalsStore.matchmakerStatistics = stats.statistics
}

useWebSocket({ event: 'AdminMessage', callback: websocketCallback })
useWebSocket({ event: 'MatchmakerStatistics', callback: updateMatchmakerStats })
useWebSocket({ event: 'LockdownAdmin', callback: lockdownAdmin })

const overrideTargets = {
  bronzeValue: 976,
  silverValue: undefined,
  goldValue: undefined,
}

const targets = computed<TotalMonthlyTargets>(() => {
  return defu(overrideTargets, monthlyTargetStore.currentQuarterlyTotalTarget)
})

const disableTargets = {
  bronze: false,
  silver: true,
  gold: true,
}

const totalBar = computed<number>(() => {
  if (!disableTargets.gold) {
    return targets.value.goldValue + 100
  } else if (!disableTargets.silver) {
    return targets.value.silverValue + 100
  } else if (!disableTargets.bronze) {
    return targets.value.bronzeValue + 100
  } else {
    return globalsStore.newMatchesCount ?? 0
  }
})

const newMatchCount = computed(() => (globalsStore.newMatchesCount ?? 0) - 1724) // Q1 + Q2 amount
const newMatchRatio = computed(() => newMatchCount.value / totalBar.value)

const bronzeRatio = computed(() => {
  if (targets.value.bronzeValue === 0) {
    return 0.7
  } else {
    return targets.value.bronzeValue / totalBar.value
  }
})
const silverRatio = computed(() => {
  if (targets.value.silverValue === 0) {
    return 0.7 * 1.19
  } else {
    return targets.value.silverValue / totalBar.value
  }
})
const goldRatio = computed(() => {
  if (targets.value.goldValue === 0) {
    return 0.7 * 1.33
  } else {
    return targets.value.goldValue / totalBar.value
  }
})

const newMatchBarColor = computed(() => {
  const newMatchesCount = newMatchCount.value
  let color

  if (!newMatchesCount) {
    color = 'danger'
  } else if (newMatchesCount < targets.value.bronzeValue) {
    color = 'bronze'
  } else if (newMatchesCount < targets.value.silverValue) {
    color = 'bronze'
  } else if (newMatchesCount < targets.value.goldValue) {
    color = 'silver'
  } else {
    color = 'gold'
  }

  return `has-background-${color}`
})

const newMatchBarZIndex = computed(() => {
  const newMatchesCount = newMatchCount.value

  if (!newMatchesCount) {
    return 1
  } else if (newMatchesCount < targets.value.bronzeValue) {
    return 7
  } else if (newMatchesCount < targets.value.silverValue) {
    return 5
  } else if (newMatchesCount < targets.value.goldValue) {
    return 3
  } else {
    return 1
  }
})

const bronzeBarColour = computed(() => {
  if (
    targets.value.bronzeValue === 0 ||
    newMatchCount.value < targets.value.bronzeValue
  ) {
    return 'has-background-grey'
  } else {
    return 'has-background-bronze'
  }
})

const initialDeficit = -230

const currentDeficit = computed(
  () =>
    (focusMunicipalityStore.groupedMunicipalityTargetStatuses.red?.matchActual ?? 0) -
    (focusMunicipalityStore.groupedMunicipalityTargetStatuses.red?.normalisedTarget ?? 0),
)

const deficitDifference = computed(() =>
  currentDeficit.value === 0 ? 0 : Math.round(initialDeficit - currentDeficit.value),
)
const deficitDifferencePercentage = computed(
  () => `${50 + (deficitDifference.value / initialDeficit) * 50}%`,
)
const deficitDifferenceLabel = computed(() => {
  if (Math.abs(deficitDifference.value) < 0.5) {
    return '0'
  } else if (deficitDifference.value < 0) {
    return `+${Math.abs(deficitDifference.value).toFixed(0)}`
  } else {
    return `-${deficitDifference.value.toFixed(0)}`
  }
})

/**
 * Deficit difference of +2 = Bronze
 * Deficit difference of +72 = Silver
 * Deficit difference of +122 = Gold
 */
const deficitColour = computed(() => {
  if (deficitDifference.value === 0) {
    return 'has-background-gray-light'
  } else if (deficitDifference.value <= -122) {
    return 'has-background-gold'
  } else if (deficitDifference.value <= -72) {
    return 'has-background-silver'
  } else if (deficitDifference.value <= -2) {
    return 'has-background-bronze'
  } else {
    return 'has-background-danger'
  }
})

onMounted(async () => {
  if (!globalsStore.doneLoading) {
    await globalsStore.fetchGlobalData()
  }

  const userHash = await authStore.fetchUserHash()

  if (userHash !== authStore.userHash || !authStore.user) {
    await authStore.check()
    authStore.userHash = userHash
  }
})
</script>

<template>
  <div>
    <div class="bottom-bar has-background-grey">
      <div
        v-if="!disableTargets.bronze"
        v-tooltip.right="`Bronzen doel: ${targets.bronzeValue}`"
        class="bronze-bar"
        :class="bronzeBarColour"
        :style="{ width: `${bronzeRatio * 100}%` }"
      ></div>
      <div
        v-if="!disableTargets.gold"
        v-tooltip.right="`Zilveren doel: ${targets.silverValue}`"
        class="silver-bar has-background-silver"
        :style="{
          width: `${(silverRatio - bronzeRatio) * 100}%`,
          left: `${bronzeRatio * 100}%`,
        }"
      ></div>
      <div
        v-if="!disableTargets.gold"
        v-tooltip.left="`Gouden doel: ${targets.goldValue}`"
        class="gold-bar has-background-gold"
        :style="{
          width: `${(goldRatio - silverRatio) * 100}%`,
          left: `${silverRatio * 100}%`,
        }"
      ></div>
      <div
        v-tooltip.right="`Gematchte personen: ${newMatchCount}`"
        class="matches-bar"
        :class="newMatchBarColor"
        :style="{ width: `${newMatchRatio * 100}%`, zIndex: newMatchBarZIndex }"
      ></div>
    </div>

    <div class="deficit-bar has-background-grey">
      <div
        v-tooltip.left="
          `Achterstand: ${currentDeficit.toFixed(0)}, verschil begin Q3: ${deficitDifferenceLabel}`
        "
        class="mid-point"
        :class="deficitDifference < 0 ? deficitColour : 'has-background-grey'"
        :style="{ width: '50%', zIndex: deficitDifference < 0 ? 2 : 1 }"
      ></div>

      <div
        v-tooltip.left="
          `Achterstand: ${currentDeficit.toFixed(0)}, verschil begin Q3: ${deficitDifferenceLabel}`
        "
        class="current-status"
        :class="deficitColour"
        :style="{
          width: deficitDifferencePercentage,
          zIndex: deficitDifference < 0 ? 1 : 2,
        }"
      ></div>
    </div>

    <EmojiPopper />

    <LockdownModal
      v-if="lockdownModalIsOpen"
      v-model:open="lockdownModalIsOpen"
      :reason="lockdownReason"
      @close="lockdownModalIsOpen = false"
    />

    <QuickAdminTaskModal
      v-model:open="taskStore.taskModalParams.open"
      :taskable-id="taskStore.taskModalParams.taskableId"
      :taskable-type="taskStore.taskModalParams.taskableType"
      @close="taskStore.closeTaskModal"
    />
  </div>
</template>

<style scoped lang="scss">
%bar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.2rem;
}

%border {
  border-right: 2px solid black;
}

.bottom-bar {
  @extend %bar;

  width: 100vw;

  .bronze-bar {
    @extend %bar;
    @extend %border;

    z-index: 6;
  }

  .silver-bar {
    @extend %bar;
    @extend %border;

    z-index: 4;
  }

  .gold-bar {
    @extend %bar;
    @extend %border;

    z-index: 2;
  }

  .matches-bar {
    @extend %bar;
    @extend %border;

    background-color: white;
  }
}

.deficit-bar {
  @extend %bar;

  bottom: -0.28rem;
  width: 100vw;

  .mid-point {
    @extend %bar;
    @extend %border;
  }

  .current-status {
    @extend %bar;
    @extend %border;
  }
}
</style>
