import { Dayjs } from 'dayjs'
import type { Role, TeamRoleEnum, UserId } from '/@src/types/users'
import type { AbstractId } from '/@src/types/utils'

export type AdminUserId = AbstractId<'admin-user'>

export type FavoriteAnimal =
  | 'cat'
  | 'dog'
  | 'axolotl'
  | 'squirrel'
  | 'platypus'
  | 'seagull'
  | 'pingu'
  | '???'

export enum TrafficLight {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
}

export interface Workdays {
  helpdesker: number[]
  matchmaker: number[]
  impactAccelerator: number[]
  marketing: number[]
  misc: number[]
}

export type WorkdaysType = keyof Workdays

export interface AdminUser {
  id: AdminUserId
  userId: UserId
  firstname: string
  lastname: string
  avatar?: string
  birthdate: Dayjs
  municipalities: string[]
  favoriteAnimal: FavoriteAnimal | null

  maxOpen: number
  maxSuggested: number
  workdays: Workdays
  lockWorkdays: boolean

  morningAvailability: TrafficLight
  afternoonAvailability: TrafficLight
  openForQuestions: boolean
  emojiOfTheDay: string | null

  awayFrom: Dayjs | null
  awayUntil: Dayjs | null

  leadUserId: UserId | null
  targetModifier: number

  roles: Role[]
}

export interface AdminUsersResponse {
  helpdeskers: AdminUser[]
  matchmakers: AdminUser[]
  exMatchmakers: AdminUser[]
  impactAccelerators: AdminUser[]
  developers: AdminUser[]
  misc: AdminUser[]
}

export type AdminUserAutomaticUpdateField = 'open_for_questions'
export type AminUserAutomaticUpdateParams = {
  field: AdminUserAutomaticUpdateField
}

type AdminUserFieldUpdateWorkDays = {
  field: 'workdays'
  value: Workdays
}
type AdminUserFieldUpdateFavoriteAnimal = {
  field: 'favorite-animal'
  value: FavoriteAnimal | null
}
type AdminUserFieldUpdateAvailability = {
  field: 'morning' | 'afternoon'
  value: TrafficLight
}
export type AdminUserFieldUpdateEmoji = {
  field: 'emoji-of-the-day'
  value: string | null
}
export type AdminUserFieldUpdateMunicipalities = {
  field: 'municipalities'
  value: string[]
}
export type AdminUserFieldUpdateOptions =
  | AdminUserFieldUpdateWorkDays
  | AdminUserFieldUpdateFavoriteAnimal
  | AdminUserFieldUpdateAvailability
  | AdminUserFieldUpdateEmoji
  | AdminUserFieldUpdateMunicipalities
export type AdminUserFieldParams = {} & AdminUserFieldUpdateOptions

export interface UpdateAdminUserForm {
  maxOpen: number
  maxSuggested: number
  leadUserId: UserId | null
  municipalities: string[]
  awayFrom: Dayjs | null
  awayUntil: Dayjs | null
  targetModifier: number
}

export type UpdateAdminUserParams = Omit<UpdateAdminUserForm, 'municipalities'> & {
  municipalities: string[]
}

export interface UpdateAdminUserWorkDaysParams {
  workdays: Workdays
}

export interface AdminUserEnpsAnswerParams {
  answer: number
  endOfMonthAnswer: number | null
}

export interface AdminUserEnpsAnswerResult {
  answer: number | undefined
  endOfMonthAnswer: number | null
}

export interface ActanAbsence {
  userId: UserId
  team: TeamRoleEnum | null
  startDate: Dayjs
  endDate: Dayjs | null
}
