<script setup lang="ts">
import html2canvas from 'html2canvas'
import JSZip from 'jszip'
import { getActivePinia } from 'pinia'
import { useBuggieStore } from '/@src/stores/buggie'
import { useSlack } from '/@src/composable/useSlack'
import { toast } from 'vue-sonner'
import { useAuthStore } from '/@src/stores/auth'
import { z } from 'zod'
import { FileStorage } from '/@src/utils/file-list'
import { useBugsStore } from '/@src/stores/bugs'
import { UserRoleEnum } from '/@src/types/users'
import { type Bug, bugPriorityIcon, bugStatusColor } from '/@src/types/bugReport'
import type { ModalSidebarItem } from '/@src/types/elements-ui'
import { dayjsUTC, formatDateAndTime } from '/@src/utils/date-formatter'
import { isManager } from '/@src/utils/entity-checks'
import { useTypedForm } from '/@src/composable/useTypedForm'
import type { SlackErrorReport, SlackPermalinkResult, SlackUser } from '/@src/types/slack'

const isOpen = defineModel<boolean>('open', { required: true })

const authStore = useAuthStore()
const bugStore = useBugsStore()

const route = useRoute()
const router = useRouter()

const checkList = reactive({
  hasRefreshed: false,
  hasLoggedOut: false,
  hasCheckedActiveBugs: false,
})

const activeBugs = ref<Bug[]>([])

const isLoading = ref(false)
const postSlackMessage = ref(true)

const fileStorage = new FileStorage(['image/png', 'image/jpeg'])

const { postMessage, getListOfUsers } = useSlack()

let listOfUsers: SlackUser[] = []

const hasGoneThroughCheckList = computed(() => Object.values(checkList).every((p) => p))

const hasDeveloperRole = computed(() => authStore.hasRole(UserRoleEnum.Developer))

watch(
  () => isOpen.value,
  async () => {
    if (isOpen.value) {
      if (listOfUsers.length === 0) {
        isLoading.value = true
        const result = await getListOfUsers()
        isLoading.value = false
        if (result) {
          listOfUsers = result
        }
      }

      const result = await bugStore.getActiveBugs()
      if (result) {
        activeBugs.value = result
      }
    }
  },
  { immediate: true },
)

const activeBugSidebarItems = computed<ModalSidebarItem[]>(() => {
  if (!isManager.value) {
    return []
  }

  return activeBugs.value.map((bug) => {
    return {
      key: `bug-sidebar-item-${bug.id}`,
      title: bug.expectedOutcome,
      color: bugStatusColor[bug.status],
      icon: bugPriorityIcon[bug.priority],
      subtitle: formatDateAndTime(bug.createdAt),
    }
  })
})

const onRefresh = async () => {
  window.location.href += '?eraseCache=true'
}

const onLogout = async () => {
  const isAdminMode = !!authStore.adminUser

  await authStore.logOut()
  toast.success('Uitgelogd.')

  if (!isAdminMode) {
    await router.push({ name: '/login' })
  }
}

const { handleSubmit, resetForm, formProps } = useTypedForm<SlackErrorReport>({
  id: 'error-reporting-modal',
  schema: {
    reproductionSteps: z.string().min(1),
    expectedOutcome: z.string().min(1),
    actualOutcome: z.string().min(1),
    link: z
      .string()
      .refine((value) => !hasDeveloperRole.value || (hasDeveloperRole.value && !!value)),
  },
  initialValues: {
    reproductionSteps: '',
    expectedOutcome: '',
    actualOutcome: '',
    link: window.location.href,
  },
})

watch(hasGoneThroughCheckList, (newValue) => {
  if (newValue) {
    resetForm({
      values: {
        reproductionSteps: '',
        expectedOutcome: '',
        actualOutcome: '',
        link: window.location.href,
      },
    })
  }
})

const sendSlackMessage = handleSubmit(async (values) => {
  isLoading.value = true

  const appElement = document.getElementById('app')

  if (!appElement) {
    isLoading.value = false
    return
  }

  const routeName = route.name.replaceAll('/', '').replaceAll('-', '_')

  const fileName = `${routeName}_${dayjsUTC().format('YYYY_MM_DD_HH_mm_ss')}`

  let userId: string | undefined = undefined

  if (authStore.user) {
    // Special cases for email mismatches
    if (authStore.user.email === 'kellydenisemeeks@gmail.com') {
      userId = 'U01NQJQDJ7R'
    } else if (authStore.user.email === 'fleur@thuisgekookt.nl') {
      userId = 'U04J0DVHB5Y'
    } else {
      const user = listOfUsers.find(
        (listUser) => listUser.profile.email === authStore.user?.email,
      )
      if (user) {
        userId = user.id
      }
    }
  }

  let blob: Blob | null
  try {
    const canvas = await html2canvas(appElement)
    blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve))
  } catch {
    blob = null
  }

  const zip = new JSZip()

  if (blob) {
    zip.file(`screenshot-${fileName}.png`, blob)
  }

  if (getActivePinia()) {
    const state = getActivePinia()?.state.value
    const piniaDataDump = JSON.stringify(state, undefined, 4)
    zip.file(`data-dump-${fileName}.json`, piniaDataDump)

    const buggieStore = useBuggieStore()

    const networkErrorsDump = JSON.stringify(
      buggieStore.networkErrors.slice().reverse(),
      undefined,
      4,
    )
    zip.file(`network-errors-dump-${fileName}.json`, networkErrorsDump)
    const googleApiDump = JSON.stringify(
      buggieStore.googleApiErrors.slice().reverse(),
      undefined,
      4,
    )
    zip.file(`google-api-errors-dump-${fileName}.json`, googleApiDump)

    const validationErrorDump = JSON.stringify(buggieStore.validationErrors, undefined, 4)
    zip.file(`validation-error-dump-${fileName}.json`, validationErrorDump)
  }

  const zipFile = await zip.generateAsync({ type: 'blob' })

  let permalinkResponse: SlackPermalinkResult | false = false
  const file = new File([zipFile], `error_${fileName}.zip`)

  if (postSlackMessage.value) {
    permalinkResponse = await postMessage(
      file,
      fileStorage.file,
      values,
      window.location.href,
      userId,
    )
  }

  if (authStore.user) {
    await bugStore.createBug({
      ...values,
      userId: authStore.user.id,
      linkToSlackMessage: permalinkResponse ? permalinkResponse?.permalink : null,
    })
  }

  isLoading.value = false
  if (permalinkResponse) {
    toast.success('Bug report verstuurd')
    isOpen.value = false

    resetForm({
      values: {
        reproductionSteps: '',
        expectedOutcome: '',
        actualOutcome: '',
        link: window.location.href,
      },
    })
    checkList.hasRefreshed = false
    checkList.hasLoggedOut = false
    checkList.hasCheckedActiveBugs = false
  } else if (!permalinkResponse && postSlackMessage.value) {
    toast.error('Er ging iets mis bij het versturen van het bug report #irony')
  }

  postSlackMessage.value = true
})
</script>

<template>
  <VModal
    v-model:open="isOpen"
    title="Buggie"
    actions="center"
    size="xlarge"
    :right-sidebar-items="activeBugSidebarItems"
    right-sidebar-title="Recente bugs"
    right-sidebar-width="20vw"
  >
    <template #content>
      <VCard>
        <template #toolbar-left>
          <VIconBox
            icon="fa-face-frown-open"
            size="large"
            font-awesome-icon-size="2x"
            rounded
            color="info"
          />
        </template>
        <template #toolbar-right>
          <div>
            <p class="title">Er gaat iets mis op pagina:</p>
            <p class="subtitle">{{ route.path }}</p>
          </div>
        </template>
        <template #content>
          <Transition name="slide-up" mode="out-in">
            <section v-if="!hasGoneThroughCheckList" class="section py-0 content">
              <p>
                Hoi! Via dit scherm kan je gemakkelijk informatie bij elkaar verzamelen om
                een fout te rapporteren. Voordat je bug report maakt, is het fijn als je
                eerst de punten hieronder naloopt. Hiervoor kan je de knoppen onderaan
                deze pop-up gebruiken. 😉
              </p>
              <p>
                Heeft dat niet gewerkt? Dan verschijnen wat velden zodra alle checkboxes
                aangevinkt zijn. Deze velden helpen Development met het sneller oplossen
                van bugs en worden vanzelf meegestuurd in een bericht naar Slack. Ook kan
                je dan een screenshot toevoegen, als je denkt dat dat Development kan
                helpen. Er wordt dan automatisch een bericht in het #bugs kanaal van Slack
                gestuurd met wat extra gegevens. Je wordt getagd in dit bericht in Slack,
                zodat je gelijk updates krijgt over de bug.
              </p>

              <div class="columns is-multiline">
                <VField class="column is-full pb-0 mb-0">
                  <VCheckbox
                    v-model="checkList.hasRefreshed"
                    value="has-refreshed"
                    label="Ik heb de pagina herladen met de knop hieronder"
                    size="large"
                  />
                </VField>
                <VField class="column is-full py-0 my-0">
                  <VCheckbox
                    v-model="checkList.hasLoggedOut"
                    value="has-logged-out"
                    label="Ik heb uit- en ingelogd"
                    size="large"
                  />
                </VField>
                <VField class="column is-full py-0 my-0">
                  <VCheckbox
                    v-model="checkList.hasCheckedActiveBugs"
                    value="has-checked-active-bugs"
                    label="Ik heb in #bugs gekeken of mijn probleem al bekend is"
                    size="large"
                  />
                </VField>
              </div>
            </section>

            <section v-else class="section py-0">
              <FormWrapper v-bind="formProps" class="columns" v-slot="{ k }">
                <div class="column is-half">
                  <div class="columns is-multiline">
                    <div v-if="hasDeveloperRole" class="column is-full">
                      <VField label="Plaats een Slack bericht">
                        <VSwitchBlock
                          v-model="postSlackMessage"
                          name="post-slack-message"
                          left-label="Niet"
                          right-label="Wel"
                        />
                      </VField>
                    </div>

                    <div class="column is-full">
                      <FormInput
                        :name="k.reproductionSteps"
                        label="Wat probeerde je te doen?"
                      >
                        <p class="is-small mb-2 ml-1">
                          Beschrijf welke stappen je nam. Bijvoorbeeld: Ik vulde het
                          nieuwe telefoonnummer in (0612345678) en klikte op opslaan.
                        </p>
                      </FormInput>
                    </div>

                    <div class="column is-full">
                      <FormInput
                        :name="k.expectedOutcome"
                        label="Wat verwachtte je dat er gebeurde?"
                      >
                        <p class="is-small mb-2 ml-1">
                          Bijvoorbeeld: Ik verwachtte dat de gegevens zouden opslaan en ik
                          terug zou gaan naar de tabel van de gebruikers.
                        </p>
                      </FormInput>
                    </div>

                    <div class="column is-full">
                      <FormInput :name="k.actualOutcome" label="Wat gebeurde er echt?">
                        <p class="is-small mb-2 ml-1">
                          Bijvoorbeeld: De gegevens sloegen niet op en ik kreeg een rode
                          melding rechtsonderin het scherm.
                        </p>
                      </FormInput>
                    </div>

                    <div v-show="hasDeveloperRole" class="column is-full">
                      <FormInput :name="k.link" label="Op welke pagina gaat het mis?">
                        <p class="is-small mb-2 ml-1">
                          Vul hier alles behalve de domeinnaam in.
                        </p>
                      </FormInput>
                    </div>
                  </div>
                </div>

                <div class="column is-half">
                  <FileDropZone v-model="fileStorage" vertical />
                </div>
              </FormWrapper>
            </section>
          </Transition>
        </template>
      </VCard>
    </template>
    <template #actions>
      <div class="column is-narrow">
        <VButton color="warning" :loading="isLoading" @click="onRefresh">
          Pagina verversen
        </VButton>
      </div>
      <div class="column is-narrow">
        <VButton color="danger" :loading="isLoading" @click="onLogout">
          Uitloggen
        </VButton>
      </div>
      <div v-if="hasGoneThroughCheckList" class="column is-narrow">
        <VButton color="primary" :loading="isLoading" @click="sendSlackMessage">
          Bug versturen
        </VButton>
      </div>
    </template>
  </VModal>
</template>

<style scoped lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
