import { acceptHMRUpdate, defineStore } from 'pinia'
import type { AxiosResponse } from 'axios'
import { LimitedArray } from '/@src/utils/LimitedArray'
import { dayjsUTC } from '/@src/utils/date-formatter'

interface State {
  networkErrors: LimitedArray<AxiosResponse & { timestamp: string }>
  // Google API is not typed so no idea what this type is
  googleApiErrors: LimitedArray<any>

  // Validation can be any kind so map to unknown
  validationErrors: Record<string, unknown>
}

export const useBuggieStore = defineStore('buggie', {
  state: (): State => ({
    networkErrors: new LimitedArray<AxiosResponse & { timestamp: string }>(20),
    googleApiErrors: new LimitedArray<any>(20),
    validationErrors: {},
  }),

  logout: {
    pick: ['networkErrors', 'googleApiErrors'],
  },

  actions: {
    pushNetworkError(error: AxiosResponse) {
      this.networkErrors.push({
        ...error,
        timestamp: dayjsUTC().toString(),
      })
    },
    pushGoogleApiError(error: any) {
      this.googleApiErrors.push({
        ...error,
        timestamp: dayjsUTC().toString(),
      })
    },
    putValidationError(key: string, errors: unknown) {
      this.validationErrors[key] = errors
    },
    removeValidationError(key: string) {
      delete this.validationErrors[key]
    },
  },
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBuggieStore, import.meta.hot))
}
