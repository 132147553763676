import type { DriverHook, DriveStep } from 'driver.js'
import { driver } from 'driver.js'
import type { Ref } from 'vue'
import type { FAIconName } from '/@src/types/elements-ui'
import type { NavigationGuard } from 'vue-router'

export interface TutorialProps {
  steps: DriveStep[]
}

type PromiseDriverHook = DriverHook | ((...args: Parameters<DriverHook>) => Promise<void>)

class OnboardingObserver {
  private driver: Ref<ReturnType<typeof driver> | undefined>

  constructor() {
    this.driver = ref<ReturnType<typeof driver>>()
  }

  private transformSteps(steps: DriveStep[], moveFunction: () => DriverHook) {
    return steps.map((step) => {
      if (!step.popover?.onNextClick) {
        return step
      }

      const onNextClick = step.popover.onNextClick as PromiseDriverHook
      step.popover.onNextClick = async (...args: Parameters<DriverHook>) => {
        const { state } = args[2]
        const nextButton = state.popover?.nextButton
        nextButton?.classList.add('is-loading', 'driver-popover-btn-disabled')
        await onNextClick(...args)
        nextButton?.classList.remove('is-loading', 'driver-popover-btn-disabled')

        const move = moveFunction()
        move(...args)
      }
      return step
    })
  }

  setTutorial = (
    tutorial: TutorialProps,
    beforeRouteLeave: (leaveGuard: NavigationGuard) => void,
  ) => {
    this.driver.value = driver({
      showProgress: true,
      steps: this.transformSteps(tutorial.steps, () => this.driver.value!.moveNext),
      progressText: 'Stap {{current}} van {{total}}',
      prevBtnText: ' ',
      nextBtnText: ' ',
      doneBtnText: ' ',
      onPopoverRender: (popover, { config, state }) => {
        const previousButton = popover.previousButton
        this.createFooterButton(previousButton, false, false)

        const currentStep = (state.activeIndex ?? 0) + 1
        const totalSteps = config.steps?.length ?? 0

        const nextButton = popover.nextButton
        this.createFooterButton(nextButton, true, currentStep === totalSteps)

        popover.closeButton.innerHTML = ''
        popover.closeButton.classList.add('delete')
      },
    })

    beforeRouteLeave(() => (this.driver.value = undefined))
    return () => (this.driver.value = undefined)
  }

  hasTutorial = computed(() => !!this.driver.value)

  private createFooterButton = (
    button: HTMLButtonElement,
    isNext: boolean,
    isDone: boolean,
  ) => {
    button.classList.add('button', 'v-button', 'is-normal')
    if (isDone) {
      button.classList.add('is-success')
    } else {
      button.classList.add('is-info')
    }

    let label: string
    let icon: FAIconName
    if (!isNext) {
      label = 'Vorige'
      icon = 'fa-arrow-left'
    } else if (isDone) {
      label = 'Sluiten'
      icon = 'fa-check'
    } else {
      label = 'Volgende'
      icon = 'fa-arrow-right'
    }

    const buttonIcon = document.createElement('i')
    buttonIcon.classList.add('fas', icon)

    const buttonIconSpan = document.createElement('span')
    buttonIconSpan.classList.add('icon')
    buttonIconSpan.appendChild(buttonIcon)

    const buttonTextSpan = document.createElement('span')
    buttonTextSpan.classList.add('icon-label')
    buttonTextSpan.innerHTML = label

    if (!isNext) {
      button.appendChild(buttonIconSpan)
    }
    button.appendChild(buttonTextSpan)
    if (isNext) {
      button.appendChild(buttonIconSpan)
    }
  }

  start = (): boolean => {
    if (!this.driver.value) {
      return false
    }

    this.driver.value.drive()

    return true
  }
}

export const onboarding = new OnboardingObserver()
