<script setup lang="ts">
import { computed } from 'vue'
import type { ComponentClass, FAIconName } from '/@src/types/elements-ui'

type VCardEmits = {
  iconClick: []
}

interface VCardProps {
  title?: string
  icon?: FAIconName
  footerRight?: boolean
  radius?: 'regular' | 'smooth' | 'rounded'

  headerClasses?: ComponentClass
  headerTitleClasses?: ComponentClass
  contentClasses?: ComponentClass
  footerClasses?: ComponentClass

  fullHeight?: boolean

  animated?: boolean
  highlight?: boolean
  lowlight?: boolean

  topRightIcon?: boolean
  iconAction?: boolean
}

const emits = defineEmits<VCardEmits>()
const props = withDefaults(defineProps<VCardProps>(), {
  title: undefined,
  icon: undefined,
  radius: 'regular',
  headerClasses: 'columns m-0 is-vcentered',
  headerTitleClasses: undefined,
  contentClasses: 'pb-5',
  footerClasses: 'pt-4',
})

const cardRadius = computed(() => {
  if (props.radius === 'smooth') {
    return 's-card'
  } else if (props.radius === 'rounded') {
    return 'l-card'
  }

  return 'r-card'
})
</script>

<template>
  <div
    class="v-card card"
    :class="[
      cardRadius,
      animated && 'is-animated',
      highlight && 'is-highlighted',
      lowlight && 'is-lowlighted',
      fullHeight && 'is-fullheight',
    ]"
  >
    <slot name="header">
      <header class="card-header" :class="headerClasses">
        <div
          v-if="$slots['title'] || title"
          class="card-header-title"
          :class="headerTitleClasses"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </div>

        <slot name="toolbar">
          <template v-if="$slots['toolbar-left'] || $slots['toolbar-right']">
            <div class="column is-flex is-narrow is-justify-content-start">
              <slot name="toolbar-left"></slot>
            </div>
            <div class="column is-flex is-narrow is-justify-content-end">
              <slot name="toolbar-right"></slot>
            </div>
          </template>
        </slot>

        <div
          v-if="$slots['icon'] || icon"
          class="card-header-icon"
          :class="[topRightIcon && 'has-top-right-icon', iconAction && 'is-clickable']"
          @click="emits('iconClick')"
          @keyup="emits('iconClick')"
        >
          <slot name="icon">
            <VIcon v-if="icon" :icon="icon" size="large" font-awesome-icon-size="2x" />
          </slot>
        </div>
      </header>
    </slot>

    <div
      class="card-content"
      :class="[
        contentClasses,
        fullHeight && $slots['footer'] && 'is-fullheight-with-footer',
        fullHeight && !$slots['footer'] && 'is-fullheight',
      ]"
    >
      <slot name="content" />
    </div>

    <footer
      v-if="$slots['footer']"
      class="card-footer"
      :class="[
        footerClasses,
        fullHeight && 'is-footer-fullheight is-flex is-align-items-center',
      ]"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<style scoped lang="scss">
.card-header:empty {
  display: none;
}

.card-content:empty {
  display: none;
}

.is-fullheight-with-footer {
  height: 90%;
}

.is-footer-fullheight {
  height: 10%;
}

.is-scrollable {
  max-height: 60vh;
  overflow-y: auto;
}
</style>
