import type { MaybeArray } from '/@src/types/utils'
import type { PersistenceOptions } from 'pinia-plugin-persistedstate'
import type { PiniaPluginContext, StateTree } from 'pinia'
import { parseDateResponse } from '/@src/utils/date-formatter'
import { isArray } from 'lodash'

type RestoreFunction = (context: PiniaPluginContext) => void

function addRestoreFunction<S extends StateTree>(
  option: PersistenceOptions<S>,
): PersistenceOptions<S> {
  if (option.afterHydrate) {
    const oldHydrate = option.afterHydrate

    const newRestoreFunction: RestoreFunction = (context) => {
      parseDateResponse(context.store)
      oldHydrate(context)
    }

    return {
      ...option,
      afterHydrate: newRestoreFunction,
    }
  } else {
    return {
      ...option,
      afterHydrate: ({ store }) => {
        parseDateResponse(store)
      },
    }
  }
}

export function usePersist<S extends StateTree>(
  options: boolean | MaybeArray<PersistenceOptions<S>> | undefined,
): MaybeArray<PersistenceOptions<S>> | undefined {
  if (typeof options === 'boolean') {
    return {
      afterHydrate: ({ store }) => {
        parseDateResponse(store)
      },
    }
  } else if (isArray(options)) {
    return options.map(addRestoreFunction)
  } else if (options) {
    return addRestoreFunction(options)
  }

  return undefined
}
