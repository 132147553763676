import type { MaybePromise } from '/@src/types/utils'

type FunctionType<T extends any[] = any[]> = (...args: T) => MaybePromise<boolean | void>

interface UseModalProps<TIn extends any[] = any[], TOut extends any[] = any[]> {
  defaultOpen?: boolean
  open?: FunctionType<TIn>
  close?: FunctionType<TOut>
  onOpen?: () => void
  onClose?: () => void
}

export function useModal<TIn extends any[] = any[], TOut extends any[] = any[]>(
  props: UseModalProps<TIn, TOut> | undefined = {},
) {
  const { defaultOpen = false, open, onOpen, close, onClose } = props

  const modalIsOpen = ref(defaultOpen)
  const openModal: FunctionType<TIn> = async (...args) => {
    if (open) {
      modalIsOpen.value = (await open(...args)) !== false
    } else {
      modalIsOpen.value = true
    }

    return modalIsOpen.value
  }

  const closeModal: FunctionType<TOut> = async (...args) => {
    if (close) {
      const result = await close(...args)
      if (!result && result != null) {
        return false
      }
    }
    modalIsOpen.value = false

    return true
  }

  if (onOpen || onClose) {
    watch(modalIsOpen, (newValue) => {
      if (newValue && onOpen) {
        onOpen()
      } else if (!newValue && onClose) {
        onClose()
      }
    })
  }

  return {
    modalIsOpen,
    openModal,
    closeModal,
  }
}
